//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FiSlash } from 'react-icons/fi'
// Context
import { useThemeContext } from '../../contexts/themeContext'
import { useAuth } from '../../contexts/useAuth'
import { useSocket } from '../../contexts/socketContext'
import { contextSchedule } from '../../contexts/schedulesContext'
//components
import { Container } from '../../components/container/container'
import { Title } from '../../components/titlePages/title'
import { TitlePage } from '../../components/titlePages/title.page'
import { Body } from '../../components/container/Body'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//utils
import moment from 'moment'
import 'moment/locale/pt-br'
import { getClients, getPartnersHours, getScheduleByDate, getUserEspecific, getUsers, getLinkePartnersWithConsultant } from '../../services/api/callAPIsFunctions/defaultCalls.api'
import { LabelInput } from '../../components/label/label.input'
import { InputAutoComplete } from '../../components/input/input.autocomplete'
import { responseError } from '../../utils/responsesFunctions/error.response'
import { Button } from '../../components/buttons/button.default'
import { LoaderWBg } from '../../components/loaders/loaderWBg'
import { ScreenModal } from '../../components/modals/notification/screenModal'
import { OperatorScheduleCreate } from '../schedule/operator.schedule.create'
import { OperatorScheduleEdit } from '../schedule/operator.schedule.edit'
import { DayCalendarPartner } from '../../components/partnerCalendar/day.calendar.partner'
import { CalendarMainPartner } from '../../components/partnerCalendar/calendar.main.partner'
import { PartnerScheduleEdit } from './partner.schedule.edit'
import { InputCheckBox } from '../../components/input/input.checkBox'
import { api } from '../../services/api/api'
import { schedulesStatusList } from '../../utils/schedulesStatusList'
import { maskHoursForTable } from '../../utils/mask/maskHours'
import { formattTimeToShow } from '../../utils/formatData/formatTimeToShow'
import { TableDefault } from '../../components/table/table.default'
import { TableMobile } from '../../components/table/table.mobile'
import { FaCalendarAlt, FaEdit } from 'react-icons/fa'
import { useScreenSizeContext } from '../../contexts/screenSizeContext'
import { LoadingPulse } from '../../components/loaders/loadingPulse'

export function PartnerSchedule({ dashboardData }) {

    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const { screenX, screenY } = useScreenSizeContext()
    const { socketConnection } = useSocket()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const [filteredEvents, setFilteredEvents] = useState([])
    const [inEditEvent, setInEditEvent] = useState({ inEdit: false, event: `` })
    const { userData } = useAuth()
    const [filteredPartners, setFilteredPartners] = useState([])
    const [selectedPartner, setSelectedPartner] = useState('')
    const [hasUpdate, setHasUpdate] = useState(false)

    //table mode
    const [selectedClient, setSelectedClient] = useState('')
    const [selectedUser, setSelectedUser] = useState('')
    const [startDate, setStartDate] = useState('')
    const [finishDate, setFinishDate] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('')
    const [pages, setPages] = useState({})
    const [clientsList, setClientsList] = useState([])
    const [filterActive, setFilterActive] = useState(false)

    useEffect(() => {
        if (dashboardData){
            let startMonth = moment().startOf('month').format('YYYY-MM-DD')
            let endMonth = moment().endOf('month').format('YYYY-MM-DD')
            let status = schedulesStatusList.filter(e => e?.id == dashboardData?.status)
            setSelectedStatus(status[0])
            setStartDate(moment(startMonth))
            setFinishDate(moment(endMonth))
            
            setFilterActive(true)
            getData(`startDate=${startMonth}&finishDate=${endMonth}${dashboardData?.status == 9999 ? '' : `&status=${dashboardData?.status}`}`, 1)
        } else {
            getData('', 1)
        }
    }, [])

    useEffect(() => {
        if (!showUniversalModal) {
            setInEditEvent({ ...inEditEvent, inEdit: false, event: `` })
        }
    }, [showUniversalModal])

    useEffect(() => {
         
        socketConnection?.on('schedule:create', async (param) => {
            if (userData?.id == param){
                getData('', 1)
            } else {
                setHasUpdate(true)
            }
        })
        socketConnection?.on('schedule:update', async (param) => {
            if (userData?.id == param){
                getData('', 1)
            } else {
                setHasUpdate(true)
            }
        })
        socketConnection?.on('schedule:updateStatus', async (param) => {
            if (userData?.id == param){
                getData('', 1)
            } else {
                setHasUpdate(true)
            }
        })
        socketConnection?.on('schedule:remove', async (param) => {
            if (userData?.id == param){
                getData('', 1)
            } else {
                setHasUpdate(true)
            }
        })
    
        return () => {
            socketConnection?.off('schedule:create')
            socketConnection?.off('schedule:update')
            socketConnection?.off('schedule:updateStatus')
            socketConnection?.off('schedule:remove')
        }

    }, [socketConnection])

    async function getData(params, page) {
        setLoading(true)

        let endpoint = params ? '/api/v1/schedule' + `?` + params : `/api/v1/schedule`
        
        try {
            const clients = await api.get('/api/v1/myClients', {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            const events = await api.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })

            const partnerListData = await getPartnersHours(userData.token)

            const userPartnerAPI = await getUserEspecific(userData.token, userData.id)

            let filteredPartners

            if (userData?.typeAccess_id == 5) {
                filteredPartners = partnerListData?.data.filter(partner => {
                    if (partner?.master_id == userPartnerAPI?.data[0]?.partners_id || partner?.id == userPartnerAPI?.data[0]?.partners_id) {
                        return partner
                    }
                })
            }
            if (userData?.typeAccess_id == 7) {
                const partnersLinked = await getLinkePartnersWithConsultant(userData.token, userData.id)

                let idsPartners = []
                partnersLinked.data.map(partner => {
                    idsPartners.push(partner.id)
                })

                filteredPartners = partnerListData?.data.filter(partner => {
                    if (idsPartners.includes(partner?.id)) {
                        return partner
                    }
                })
            }

            let partnersId = []

            filteredPartners?.map(partner => {
                partnersId.push(partner?.id)
            })
            setFilteredPartners(filteredPartners)

            let arrayPages = Array(events?.data?.totalPages).fill(0)
            let formattedArray = []
            arrayPages.map((page, index) => {
                formattedArray.push(index + 1)
            })
            setFilteredEvents(await Promise.all(
                events?.data?.data.map(async event => {

                    const getScheduleLabels = await api.get(`/api/v1/scheduleLabels?schedule_id=${event.id}`, {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    })
                    const labels = getScheduleLabels?.data?.data

                    const client = clients?.data?.data.filter(e => e?.id == event?.clients_id ? e?.firstname : '')
                    const statusDescription = schedulesStatusList.filter(e => e?.id === event?.status ? e?.description : '')
                    const formatDateSchedule = event?.scheduleDate.split('T')[0].split('-')
                    return {
                        ...event,
                        clients_name: client[0]?.firstname,
                        schedulesDescription: `${maskHoursForTable(event?.startHour)} às ${maskHoursForTable(event?.finishHour)} - ${formatDateSchedule[2]}/${formatDateSchedule[1]}/${formatDateSchedule[0]}`,
                        created_at: formattTimeToShow(event?.created_at),
                        statusDescription: statusDescription[0]?.description,
                        labels: (
                            <div className='flex flex-row items-center w-full justify-center'>
                                {
                                    labels?.slice(0,4)?.map((label, index) => (
                                        <Tippy content={'#' + label?.description}
                                            arrow={true}
                                            animation='perspective'
                                            placement='top'
                                            delay={50}>
                                            <p 
                                                style={{ backgroundColor: label?.color ?? '#0B2E58' }}
                                                className={`w-6 h-6 rounded-full -ml-3`}
                                            />
                                        </Tippy>                                        
                                    ))
                                }
                                {
                                    labels?.length > 4 &&
                                    <Tippy 
                                        content={labels?.slice(4, labels?.length)?.map(item => '#' + item?.description)?.join(' | ')}
                                        arrow={true}
                                        animation='perspective'
                                        placement='top'
                                        delay={50}>
                                        <p 
                                            className={`text-xs w-6 h-6 rounded-full -ml-3 bg-white flex justify-center items-center border border-gray-300`}
                                        >
                                            {labels?.slice(4, labels?.length)?.length}+
                                        </p>
                                    </Tippy>                                    
                                }
                            </div>
                        ),
                    }
                })
            ))
            setPages({
                totalPages: arrayPages?.length,
                actualPage: params ? page : 1,
                page: formattedArray
            })

            setClientsList(structuredClone(clients?.data?.data))
            setLoading(false)

        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    function handleNewPage(page) {

        let params = []

        selectedClient?.id && params.push(`clients_id=${selectedClient?.id}`)
        selectedUser?.id && params.push(`users_created=${selectedUser?.id}`)
        selectedPartner?.id && params.push(`partners_id=${selectedPartner?.id}`)
        selectedStatus?.id ? params.push(`status=${selectedStatus?.id}`) : ''
        startDate && params.push(`startDate=${startDate.format('YYYY-MM-DD')}`)
        finishDate && params.push(`finishDate=${finishDate.format('YYYY-MM-DD')}`)
        params = params.join('&')

        getData(`${params}&page=${page}`, page)
    }

    async function handleSchedulesReport() {

        let yearElement = document.getElementById('startDate')
        let monthElement = document.getElementById('finishDate')

        if (finishDate < startDate) {
            setFinishDate('')
            setStartDate('')
            setShowModificationModal(true)
            setShowNotificationModalSuccess(false)
            monthElement.style.border = '1px solid red'
            yearElement.style.border = '1px solid red'
            return setShowNotificationModalText('Data final não pode ser menor que a data inicial')
        }

        setShowModificationModal(false)
        monthElement.style.border = ''
        yearElement.style.border = ''

        let params = []

        selectedClient?.id && params.push(`clients_id=${selectedClient?.id}`)
        selectedUser?.id && params.push(`users_created=${selectedUser?.id}`)
        selectedPartner?.id && params.push(`partners_id=${selectedPartner?.id}`)
        selectedStatus !== '' && params.push(`status=${selectedStatus?.id}`)
        startDate && params.push(`startDate=${startDate.format('YYYY-MM-DD')}`)
        finishDate && params.push(`finishDate=${finishDate.format('YYYY-MM-DD')}`)
        params = params.join('&')

        setFilterActive(true)
        getData(params, 1)
    }

    function clearStatesModeTable() {
        setSelectedClient('')
        setSelectedUser('')
        setSelectedPartner('')
        setStartDate('')
        setFinishDate('')
        setSelectedStatus('')
    }

    function editEvent(e) {
        setShowModificationModal(false)
        setInEditEvent({ ...inEditEvent, inEdit: true, event: e })
        setShowUniversalModal(true)
    }

    async function handleEdit(e) {
        editEvent(e)
    }

    const tableThead = [
        {
            "name": 'Agendado por',
            "original_name": 'users_created_name'
        },
        {
            "name": 'Cliente',
            "original_name": 'clients_name'
        },
        {
            "name": 'Parceiro',
            "original_name": 'partners_name'
        },
        {
            "name": 'Agendado',
            "original_name": 'schedulesDescription'
        },
        {
            "name": 'Tags',
            "original_name": 'labels'
        },
        {
            "name": 'Status',
            "original_name": 'statusDescription'
        },
        {
            "name": 'Criado em',
            "original_name": 'created_at'
        }
    ]

    return (
        <>
            <div className={`${hasUpdate ? 'flex overflow-hidden' : 'hidden overflow-hidden'}`}>
                <div className={`bg-orange-300`}>
                    <div className={`${hasUpdate && 'Right_To_Center'} bg-orange-300 flex flex-row justify-center duration-200 z-[999] transition-all right-0 top-0 w-[20rem] p-2 absolute rounded-md opacity-1 mt-[4.8rem] mr-[1rem] shadow-xl h-[5rem]`}>
                        <div className={`flex justify-center items-center text-white flex-col`}>
                            <p className='text-sm'>Atualizações disponíveis</p>
                            <p className='text-sm'>
                                <a className='underline text-black cursor-pointer hover:text-white duration-200' onClick={() => {setHasUpdate(false); getData('', 1)}}>Clique aqui </a> 
                                para atualizar
                            </p>
                        </div>
                        <Tippy
                            content={'Fechar'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={80}>
                            <button className={`w-[2rem] h-[2rem] absolute justify-center items-center right-0 border-none rounded-lg outline-none cursor-pointer opacity-1 mr-[0.2rem] pb-[2px] pl-[1px] hover:duration-[200ms] hover:opacity-5`} onClick={() => {setHasUpdate(false)}}>x</button>                            
                        </Tippy>
                    </div>
                </div>
            </div>
            <ScreenModal>
                {
                    inEditEvent.inEdit &&
                    <PartnerScheduleEdit selectedEvent={inEditEvent.event} />
                }
            </ScreenModal>
            <Container>
                <>
                    <TitlePage>
                        <div className='flex flex-row px-2 justify-between lg:justify-start gap-4 items-center w-full'>
                            <Title text={'Agenda comercial'} />
                        </div>
                    </TitlePage>
                    <Body dontAnimate={true} hasFooter={dashboardData && screenX > 640 ? true : false}>
                        <div className='flex flex-col items-start justify-start w-full'>
                            <div className='flex flex-row gap-2 flex-wrap items-center justify-start'>
                                <LabelInput text={`Cliente`}>
                                    <InputAutoComplete onChange={(e) => setSelectedClient(e)} preSelectedValue={selectedClient ? selectedClient?.firstname : ''} width={64} data={clientsList} selectedLabel={'firstname'} optionList={['id', 'firstname']} />
                                </LabelInput>
                                {
                                    [5,7].includes(userData?.typeAccess_id) &&
                                    <LabelInput text={`Parceiro`}>
                                        <InputAutoComplete onChange={(e) => setSelectedPartner(e)} preSelectedValue={selectedPartner ? selectedPartner?.name : ''} width={64} data={filteredPartners} selectedLabel={'name'} optionList={['id', 'name']} />
                                    </LabelInput>
                                }
                                <LabelInput text={`Status`}>
                                    <InputAutoComplete onChange={(e) => setSelectedStatus(e)} width={64} preSelectedValue={selectedStatus ? selectedStatus?.description : ''} value={selectedStatus ? selectedStatus?.description : ''} data={schedulesStatusList} selectedLabel={'description'} optionList={['id', 'description']} />
                                </LabelInput>
                                <LabelInput text={`Data inicial`}>
                                    <input type='date' id={'startDate'} width={"200px"} className="text-base h-8 text-primaryDefaultLight p-1 border border-titleGrayTextDark dark:text-primaryDefaultDarkColor dark:bg-secondaryDefaultDark" value={startDate && startDate?.clone()?.format('YYYY-MM-DD')} onChange={(e) => setStartDate(moment(e.target.value))} />
                                </LabelInput>
                                <LabelInput text={`Data final`}>
                                    <input type='date' id={'finishDate'} onChange={(e) => setFinishDate(moment(e.target.value))} width={64} className="h-8 p-1 border border-titleGrayTextDark text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor dark:bg-secondaryDefaultDark" value={finishDate && finishDate?.clone()?.format('YYYY-MM-DD')} />
                                </LabelInput>
                                <div className='mt-6 flex'>
                                    <Button shadow={true} onClick={() => handleSchedulesReport()}>Buscar</Button>
                                </div>
                                <div className='mt-6 flex items-center justify-center cursor-pointer text-lg'>
                                    {
                                        filterActive &&
                                        <Tippy
                                            content={<a>Limpar Filtros</a>}
                                            arrow={true}
                                            animation='shift-away'
                                            placement='top'
                                            delay={80}>
                                            <div>
                                                <FiSlash onClick={() => { getData(); clearStatesModeTable(); setFilterActive(false) }} />
                                            </div>
                                        </Tippy>
                                    }
                                </div>
                            </div>
                            {
                                loading ?
                                <div className='flex flex-row items-center justify-center w-full mt-5'>
                                    <LoadingPulse />
                                    <p className='text-sm'>Carregando dados...</p>
                                </div>
                                :
                                <>
                                    <div id='SchedulesTableContent' className='hidden sm:flex flex-col items-center justify-center w-full mt-8 boxShadow  overflow-y-auto rounded-lg'>
                                        <TableDefault
                                            onClick={(e) => handleEdit(e)}
                                            title={tableThead}
                                            fieldFilter={true}
                                            data={filteredEvents}
                                            collumns={["users_created_name", "clients_name", "partners_name", "schedulesDescription", "labels", "statusDescription", "created_at"]}
                                        />
                                        <div className='flex flex-row w-full items-end justify-end px-2'>
                                            {
                                                pages?.page?.map((thisPage, index) => {
                                                    const totalPages = pages?.page.length

                                                    const buttonTrue = <a onClick={() => handleNewPage(thisPage)} className='text-white px-2 bg-primaryDefaultLight hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage}</a>
                                                    const buttonFalse = <a onClick={() => handleNewPage(thisPage)} className='px-2 hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage}</a>
                                                    let existsPoints = false
                                                    let showPointers = false

                                                    if (totalPages < 6) {
                                                        return pages?.actualPage === thisPage ?
                                                            buttonTrue
                                                            :
                                                            buttonFalse
                                                    } else {
                                                        existsPoints = true
                                                        if (index + 1 === 1 && pages?.actualPage !== thisPage) {
                                                            return buttonFalse
                                                        } else if (index + 1 == thisPage && pages?.actualPage === thisPage) {
                                                            return buttonTrue
                                                        } else if (pages?.actualPage + 3 > index + 1 && pages?.actualPage - 2 < index + 1) {
                                                            return buttonFalse
                                                        } else if (totalPages === thisPage) {
                                                            return buttonFalse
                                                        } else {
                                                            if (pages?.actualPage + 3 < index + 1 || pages?.actualPage - 2 > index + 1) {
                                                                showPointers = true
                                                            }
                                                            if (existsPoints && showPointers == false) {
                                                                return <a>...</a>
                                                            }
                                                        }
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div id='SchedulesMobileTableContent' className='sm:hidden flex flex-col items-center justify-center w-full mt-8 boxShadow  overflow-y-auto rounded-lg'>
                                        <TableMobile
                                            onClick={(e) => e.func.execute(e.data)}
                                            title={tableThead}
                                            data={filteredEvents}
                                            functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                            collumns={["users_created_name", "clients_name", "partners_name", "schedulesDescription", "labels", "statusDescription", "formatHasConfirmed", "created_at"]}
                                        />
                                        <div className='flex flex-row w-full items-end justify-end px-2'>
                                            {
                                                pages?.page?.map((thisPage, index) => {
                                                    const totalPages = pages?.page.length

                                                    const buttonTrue = <a onClick={() => handleNewPage(thisPage)} className='text-white px-2 bg-primaryDefaultLight hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage}</a>
                                                    const buttonFalse = <a onClick={() => handleNewPage(thisPage)} className='px-2 hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage}</a>
                                                    let existsPoints = false
                                                    let showPointers = false

                                                    if (totalPages < 6) {
                                                        return pages.actualPage === thisPage ?
                                                            buttonTrue
                                                            :
                                                            buttonFalse
                                                    } else {
                                                        existsPoints = true
                                                        if (index + 1 === 1 && pages.actualPage !== thisPage) {
                                                            return buttonFalse
                                                        } else if (index + 1 == thisPage && pages.actualPage === thisPage) {
                                                            return buttonTrue
                                                        } else if (pages.actualPage + 3 > index + 1 && pages.actualPage - 2 < index + 1) {
                                                            return buttonFalse
                                                        } else if (totalPages === thisPage) {
                                                            return buttonFalse
                                                        } else {
                                                            if (pages.actualPage + 3 < index + 1 || pages.actualPage - 2 > index + 1) {
                                                                showPointers = true
                                                            }
                                                            if (existsPoints && showPointers == false) {
                                                                return <a>...</a>
                                                            }
                                                        }
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </Body>

                </>
            </Container>
        </>
    )
}