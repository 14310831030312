// hooks 
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
import { Container } from '../../../components/container/container'
import { Body } from '../../../components/container/Body'
import { TablePaginationDefault } from '../../../components/table/tablePagination.default'
// components
import { Title } from '../../../components/titlePages/title'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Button } from '../../../components/buttons/button.default'
import { LabelInput } from '../../../components/label/label.input'
import { FiSlash } from 'react-icons/fi'
import { FaEdit, FaFilter } from 'react-icons/fa'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/perspective.css'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Input } from '../../../components/input/input'
import { NewTableDefault } from '../../../components/table/new.table.default'
import { getPartnersHours, getUserEspecific } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
import { mCPF } from '../../../utils/validators/cpfMask'
import { Footer } from '../../../components/footer/Footer'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { api } from '../../../services/api/api'

export function TransferClientsPartner() {

    const navigate = useNavigate()
    const { showUniversalModal, setShowUniversalModal, setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [showNotification, setShowNotication] = useState('')
    const [successData, setSuccessData] = useState(false)
    const { userData } = useAuth()
    const [filterConstructor, setFilterConstructor] = useState({})
    const [filter, setFilter] = useState({ active: true })
    const [loading, setLoading] = useState(false)
    const [ selectedClients, setSelectedClients ] = useState([])
    const [ selectedPartner, setSelectedPartner ] = useState('')
    const [ selectedPartnerFrom, setSelectedPartnerFrom ] = useState('')
    const [filteredPartners, setFilteredPartners] = useState([])
    const [valueSearch, setValueSearch] = useState('')

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (!showUniversalModal){
            setSelectedPartnerFrom('')
        }
    },[ showUniversalModal ])

    async function getData() {
        try {
            setLoading(true)

            const partnerListData = await getPartnersHours(userData.token)
            const userPartnerAPI = await getUserEspecific(userData.token, userData.id)
            let filteredPartners = partnerListData?.data

            if (userData?.typeAccess_id == 5) {
                filteredPartners = partnerListData?.data.filter(partner => {
                    if (partner?.master_id == userPartnerAPI?.data[0]?.partners_id || partner?.id == userPartnerAPI?.data[0]?.partners_id) {
                        return partner
                    }
                })
            }

            setFilteredPartners(filteredPartners)
            setLoading(false)

        } catch(error){
            console.log(error)
        }
    }

    async function handleTransfer(){
        console.log(selectedPartnerFrom)
        console.log(selectedClients)
        const requiredFields = [
            { name: 'selectedPartnerFrom', value: selectedPartnerFrom, required: true, type: 'object' }
        ]

        setLoading(true)

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.put('api/v1/linkClientsAndPartners/transferClients', {
                partners_id: selectedPartner?.id,
                partners_from: selectedPartnerFrom?.id, 
                clients: selectedClients?.map(client => { return client?.id})
            }, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        setShowUniversalModal(false)
        setShowModificationModal(true)
        setShowNotificationModalSuccess(true)
        setShowNotificationModalText('Transferência realizada com sucesso')
        setLoading(false)
    }

    return (
        <>
            <ScreenModal title={'Tranferir'}>
                <div className='flex flex-col pt-2 pb-10 px-12 gap-4 items-center'>
                    <label className='flex flex-col gap-1 items-start justify-center'>
                        <a className='text-sm text-inputPlaceholderLight'>Parceiro (destino) *</a>
                        <LabelInput>
                            <InputAutoComplete 
                                id='selectedPartnerFrom'
                                onChange={(e) => {
                                    setSelectedPartnerFrom(e)
                                }} 
                                width={80}
                                height={80}
                                data={filteredPartners?.filter(part => part?.id != selectedPartner?.id)}
                                preSelectedValue={selectedPartnerFrom ? selectedPartnerFrom?.name : ''}
                                selectedLabel={'name'} 
                                optionList={['id', 'name']}
                            />
                        </LabelInput>
                    </label>
                    <div className='mt-5'>
                        <Button onClick={() => handleTransfer()}>Transferir para o parceiro selecionado</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage displayNotification={showNotification} success={successData} setDisplayNotification={e => setShowNotication(e)}>
                    <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Transferir clientes'}/>
                        </div>
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <div className='flex flex-col items-center justify-center w-full h-full'>
                            <DefaultLoader />
                        </div>
                        : selectedPartner?.id ?
                        <>
                            <div className='flex items-start justify-between w-full'>
                                <form 
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        if (!valueSearch){
                                            delete filter.text
                                            setFilter({...filter })
                                            return
                                        }
                                        setFilter({...filter, text: valueSearch})
                                    }}>
                                    <LabelInput>
                                        <Input
                                            id={'filterText'}
                                            value={valueSearch ? valueSearch : ''}
                                            onChange={(e) => setValueSearch(e?.target.value)}
                                            placeholder={'Pesquisar'}
                                        />
                                    </LabelInput>   
                                </form>
                                {
                                    selectedClients?.length > 0 ?
                                    <Button onClick={() => setShowUniversalModal(true)}>Transferir</Button>
                                    : <></>
                                }
                            </div>
                            <div className='w-full'>
                                <NewTableDefault
                                    key={'table-transfer'}
                                    endpoint={`/api/v1/linkClientsAndPartners`}
                                    token={userData.token}
                                    onSelect={(e) => {
                                        setSelectedClients(e)
                                    }}
                                    withSelection={true}
                                    columns={[
                                        {
                                            field: 'id',
                                            type: 'number',
                                            description: 'Código'
                                        },
                                        {
                                            field: 'firstname',
                                            type: 'string',
                                            description: 'Nome',
                                            newFieldValue: item => {
                                                return item?.lastname ? `${item?.firstname} ${item?.lastname}` : item?.firstname
                                            }
                                        },
                                        {
                                            field: 'cpf',
                                            type: 'string',
                                            newFieldValue: (item) => {
                                                if (item?.cpf) {
                                                    return mCPF(item?.cpf?.toString())
                                                }
                                            },
                                            description: 'CPF'
                                        },
                                        {
                                            field: 'email',
                                            type: 'string',
                                            newFieldValue: (item) => {
                                                if (item?.email) {
                                                    return item?.email
                                                } else {
                                                    return ' - '
                                                }
                                            },
                                            description: 'E-mail'
                                        },
                                        {
                                            field: 'created_at',
                                            type: 'date',
                                            description: 'Criado em'
                                        }
                                    ]}
                                    filters={filter}
                                    usePagination={true}
                                    mapQuantityOfRegisters={['quantity']}
                                    mapTotalPages={['totalPages']}
                                />  
                            </div>
                        </>
                        : 
                        <div className='w-full flex items-center justify-center'>
                            <LabelInput text={'Selecione um parceiro'}>
                                <InputAutoComplete 
                                    onChange={(e) => {
                                        setSelectedPartner(e)
                                        if (typeof(e) == 'object'){
                                            setFilterConstructor({ ...filterConstructor, partners_id: e?.id })
                                            setFilter({...filter, partners_id: e?.id})
                                        }
                                    }} 
                                    width={64} 
                                    data={filteredPartners} 
                                    selectedLabel={'name'} 
                                    optionList={['id', 'name']} 
                                />
                            </LabelInput>
                        </div>
                    }
                </Body>
                {
                    selectedPartner?.id ?
                    <Footer>
                        <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                            <Button
                                id='cancelUserEdit'
                                approval={false}
                                shadow={true}
                                onClick={() => {
                                    setSelectedPartner('')
                                    setFilter({ active: true })
                                    setValueSearch('')
                                    setFilterConstructor({ })
                                }}
                            >Voltar
                            </Button>
                        </div>
                    </Footer>
                    : <></>
                }
                
            </Container>
        </>
    )

}