//hooks
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FiPlus, FiMinus, FiX, FiCopy } from 'react-icons/fi'
//context
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
//components
import { Container } from '../../../components/container/container'
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Footer } from '../../../components/footer/Footer'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { cnpjMask } from '../../../utils/validators/cnpjMask'
import { mCPF } from '../../../utils/validators/cpfMask'
import { validateFields } from '../../../utils/form.validator'
import { validateEmail } from '../../../utils/validators/email.validator'
import { textMask } from '../../../utils/validators/textMask'
import { maskCep } from '../../../utils/validators/cepMask'
import { api } from '../../../services/api/api'
import { oabValidate } from '../../../utils/validators/oab.validator'
import { justNumber } from '../../../utils/validators/justNumber'
import { v4 as uuid } from 'uuid'
import { listHours } from '../../../utils/listHours'
import { validatorHour } from '../../../utils/validators/validatorHour'
import { maskPhone } from '../../../utils/validators/phoneMask'
import { removePontuaction } from '../../../utils/validators/removePontuaction'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { FaCircle, FaQuestionCircle } from 'react-icons/fa'

export function PartnersCreate() {
    const { screenX, screenY } = useScreenSizeContext()
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    //dados matriz
    const [name, setName] = useState('')
    const [selectedTypePerson, setSelectedTypePerson] = useState('')
    const [cnpjcpf, setCnpjCpf] = useState('')
    const [oabMaster, setOabMaster] = useState('')
    const [isValidOab, setIsValidOab] = useState(true)
    const [isValidEmail, setIsValidEmail] = useState(true)
    const [emailMaster, setEmailMaster] = useState('')
    const [dddphone, setDddphone] = useState('')
    const [phone, setPhone] = useState('')
    const [cep, setCep] = useState('')
    const [addressState, setAddressState] = useState('')
    const [addressStateList, setAddressStateList] = useState([])
    const [addressCityList, setAddressCityList] = useState([])
    const [addressCity, setAddressCity] = useState('')
    const [addressDistrict, setAddressDistrict] = useState('')
    const [addressRoad, setAddressRoad] = useState('')
    const [addressNumber, setAddressNumber] = useState(0)
    const [addressComplement, setAddressComplement] = useState('')
    const [selectedPartnerCompanyType, setSelectedPartnerCompanyType] = useState('')
    const [listMatriz, setListMatriz] = useState([])
    const [master_id, setMaster_id] = useState('')
    const [hours, setHours] = useState({})
    const [listHoursId, setListHoursId] = useState([])
    const [selectedSchedulesType, setSelectedSchedulesType] = useState({ id: 0, description: 'Livre', hour: 0 })
    const [selectedPartnerBlock, setSelectedPartnerBlock] = useState(true)
    const [selectedIsRemote, setSelectedIsRemote] = useState(false)
    
    const [firstStep, setFirstStep] = useState(false)
    const [partnersId, setPartnersId] = useState(null)
    //dados filiais
    const [branch, setBranch] = useState({})
    const [branchRows, setBranchRows] = useState(Object.keys(branch))

    const partnerType = [
        { 'id': 1, 'name': 'Matriz' },
        { 'id': 2, 'name': 'Filial' },
    ]

    const typePerson = [
        { 'id': 'PF', 'name': 'Pessoa Física' },
        { 'id': 'PJ', 'name': 'Pessoa Jurídica' }
    ]

    const weekday = [
        { 'id': 0, 'name': 'Domingo' },
        { 'id': 1, 'name': 'Segunda-Feira' },
        { 'id': 2, 'name': 'Terça-Feira' },
        { 'id': 3, 'name': 'Quarta-Feira' },
        { 'id': 4, 'name': 'Quinta-Feira' },
        { 'id': 5, 'name': 'Sexta-Feira' },
        { 'id': 6, 'name': 'Sábado' }
    ]

    const fixedHoursSchedule = [
        { id: 0, description: 'Livre', value: 0 },
        { id: 1, description: '15 minutos', value: 15 },
        { id: 2, description: '30 minutos', value: 30 },
        { id: 3, description: '1 hora', value: 60 },

    ]

    const options = [
        {
            description: 'Sim',
            value: true
        },
        {
            description: 'Não',
            value: false
        },
    ]

    useEffect(() => {

        async function getPartnersType() {
            try {

                const searchPartners = await api.get(`/api/v1/partners?type=m`, {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('@auth:token')}`
                    }
                })

                const filterPartners = searchPartners?.data?.data.filter(e => {
                    return e.isMaster === true
                })

                setListMatriz(filterPartners)
                setShowModificationModal(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getPartnersType()

    }, [])

    useEffect(() => {

        async function getStates() {

            setLoading(true)

            try {

                const getState = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
                const getStateData = await getState.json()

                setAddressStateList(getStateData.sort((a, b) => a.sigla > b.sigla ? 1 : -1))
                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        getStates()

    }, [])

    useEffect(() => {

        setAddressCity('')

    }, [addressState])

    async function handleSubmit() {

        setLoading(true)

        const requiredFields = [
            { name: 'name', value: name, required: true, type: 'string' },
            { name: 'typePerson', value: selectedTypePerson, required: true, type: 'string' },
            { name: 'cnpjcpf', value: cnpjcpf, required: true, type: 'string' },
            { name: 'partnerType', value: selectedPartnerCompanyType, required: true, type: 'string' },
            { name: 'addressState', value: addressState, required: true, type: 'string' },
            { name: 'addressCity', value: addressCity, required: true, type: 'string' }
        ]

        if (selectedPartnerCompanyType?.id === 1) {
            if (!isValidOab) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText('OAB inválida')
                return setLoading(false)
            }
            requiredFields.push({ name: 'oab', value: oabMaster, required: true, type: 'string' })
        }

        if (selectedPartnerCompanyType?.id !== 1) {
            requiredFields.push({ name: 'master_id', value: master_id, required: true, type: 'string' })
        }

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')


        }

        if (Object.keys(hours).length > 0) {
            if (validatorHour(hours).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Preencha os horários em vermelho')

            }
        }
        let createPartner
        try {
            setLoading(true)

            if (!isValidEmail) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText('Email inválido')
                return setLoading(false)

            }

            if (firstStep == false) {
                createPartner = await api.post('api/v1/partners', {
                    name,
                    cep: cep ? parseInt(cep.replace('-', '')) : undefined,
                    addressRoad: addressRoad ? addressRoad : undefined,
                    addressNumber: addressNumber ? addressNumber : undefined,
                    addressDistrict: addressDistrict ? addressDistrict : undefined,
                    addressCity: addressCity?.nome ? addressCity?.nome : undefined,
                    addressState: addressState?.sigla ? addressState?.sigla : undefined,
                    addressComplement: addressComplement ? addressComplement : undefined,
                    addressState_externalId: addressState?.id ? addressState?.id.toString() : undefined,
                    addressCity_externalId: addressCity?.id ? addressCity?.id.toString() : undefined,
                    microregion_externalId: addressCity?.microrregiao?.id ? addressCity?.microrregiao?.id.toString() : undefined,
                    mesoregion_externalId: addressCity?.microrregiao?.mesorregiao?.id ? addressCity?.microrregiao?.mesorregiao?.id.toString() : undefined,
                    dddphone: dddphone ? dddphone : undefined,
                    phone: phone ? removePontuaction(phone) : undefined,
                    email: emailMaster ? emailMaster : undefined,
                    oab: oabMaster ? oabMaster : undefined,
                    cnpjcpf: cnpjcpf ? cnpjcpf.replace('-', '').replace('/', '').replaceAll('.', '') : undefined,
                    partnersType: selectedTypePerson ? selectedTypePerson?.id : undefined,
                    isMaster: selectedPartnerCompanyType.id == 1,
                    master_id: !(selectedPartnerCompanyType.id == 1) ? master_id : undefined,
                    timeInterval: selectedSchedulesType?.id > 0 ? selectedSchedulesType?.value : undefined,
                    partnerBlock: selectedPartnerBlock ? selectedPartnerBlock : false,
                    isRemote: selectedIsRemote
                }, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`
                    }
                })

                setFirstStep(true)
                setPartnersId(createPartner?.data?.data[0].id)

            }

            if (Object.keys(hours).length > 0) {
                const partnerHours = await api.post('api/v1/partnersHours', {
                    partners_id: !partnersId ? createPartner?.data?.data[0].id : partnersId,
                    hours: Object.keys(hours).map(key => hours[key])
                }, {
                    headers: {
                        authorization: `Bearer ${userData.token}`
                    }
                })

            }

            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/partners')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado, verifique todos os campos')
            }
        }

        setLoading(false)

    }

    async function getCityApi(data) {

        setLoading(true)

        try {

            const getCity = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data?.id}/municipios`)
            const getCityData = await getCity.json()

            setAddressCityList(getCityData)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function addHour(duplicate) {

        const id = uuid()
        let add

        if (duplicate) {
            const lastId = listHoursId[listHoursId.length - 1]

            add = Object.assign(hours, {
                [id]: {
                    weekday: hours[lastId]?.weekday + 1 <= 6 ? hours[lastId]?.weekday + 1 : undefined,
                    startHour: hours[lastId]?.startHour,
                    finishHour: hours[lastId]?.finishHour
                }
            })
        } else {
            add = Object.assign(hours, {
                [id]: {
                    weekday: null,
                    startHour: null,
                    finishHour: null
                }
            })
        }
        setHours(add)
        setListHoursId([...listHoursId, id])
    }

    async function removeHour(id) {

        setLoading(true)
        await new Promise(resolve => setTimeout(resolve, 50))

        const replicaHours = hours
        const replicaListHours = listHoursId

        const position = listHoursId.indexOf(id)
        replicaListHours.splice(position, 1)

        delete replicaHours[id]

        setHours(replicaHours)
        setListHoursId([...replicaListHours])
        setLoading(false)
    }

    return (
        <Container>
            <TitlePage>
                <div className='flex justify-center lg:justify-start'>
                    <Title text={'Cadastro de Empresas - Parceiros'} />
                </div>
            </TitlePage>
            <Body hasFooter={true}>
                <div className='flex flex-col items-start justify-start w-full'>
                    {
                        loading ?
                            <div className='flex flex-col w-full items-center justify-center'>
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <div className='flex flex-col lg:items-start items-center justify-start w-full'>
                                    <form className='flex flex-col items-center  lg:grid lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>

                                        <LabelInput text={'Nome *'}>

                                            <Input type={'text'} autoFocus={true} value={name} id='name' onChange={(e) => setName(textMask(e.target.value))} charLimit={100} />
                                        </LabelInput>
                                        <LabelInput text={'Tipo Pessoa *'}>
                                            <InputAutoComplete
                                                preSelectedValue={selectedTypePerson.name}
                                                id='typePerson'
                                                selectedLabel={'name'}
                                                data={typePerson}
                                                optionList={['name']}
                                                onChange={e => setSelectedTypePerson(e)}>
                                            </InputAutoComplete>
                                        </LabelInput>
                                        {
                                            selectedTypePerson?.id === 'PJ' ?
                                                <LabelInput text={'CNPJ *'}>
                                                    <Input type={'text'} value={cnpjMask(cnpjcpf)} id='cnpjcpf' onChange={(e) => setCnpjCpf(e.target.value)} charLimit={18} />
                                                </LabelInput>
                                                : selectedTypePerson?.id === 'PF' ?
                                                    <LabelInput text={'CPF *'}>
                                                        <Input type={'text'} value={mCPF(cnpjcpf)} id='cnpjcpf' onChange={(e) => setCnpjCpf(e.target.value)} charLimit={14} />
                                                    </LabelInput>
                                                    :
                                                    <>
                                                    </>
                                        }
                                        <LabelInput text={selectedPartnerCompanyType && selectedPartnerCompanyType?.id === 1 ? 'OAB *' : 'OAB'} valid={!isValidOab ? false : true}>
                                            <Input type={'text'} id='oab' value={oabMaster} charLimit={8} onChange={(e) => {
                                                const isValid = oabValidate(e.target.value)
                                                if (!isValid) {
                                                    setIsValidOab(false)
                                                } else {
                                                    setIsValidOab(true)
                                                }
                                                setOabMaster(e.target.value)
                                            }} />
                                        </LabelInput>
                                        <LabelInput text={'E-mail'} valid={!isValidEmail ? false : true}>
                                            <Input type={'text'} value={emailMaster} id='email' onChange={(e) => {
                                                const isValid = validateEmail(e.target.value)
                                                if (isValid === true) {
                                                    setIsValidEmail(true)
                                                } else {
                                                    setIsValidEmail(false)
                                                }
                                                setEmailMaster(e.target.value)
                                            }} charLimit={255} />
                                        </LabelInput>
                                        <div className='flex lg:hidden flex-row gap-2'>
                                            <LabelInput text={'DDD'}>
                                                <Input width={'56px'} type={'text'} id='dddphone' value={dddphone} onChange={(e) => setDddphone(justNumber(e.target.value))} charLimit={2} />
                                            </LabelInput>
                                            <LabelInput text={'Telefone'}>
                                                <Input type={'text'} width={'255px'} value={phone} id='phone' onChange={(e) => setPhone(maskPhone(e.target.value))} charLimit={10} />
                                            </LabelInput>
                                        </div>
                                        <div className='hidden lg:flex flex-col sm:flex-row gap-2'>
                                            <LabelInput text={'DDD'}>
                                                <Input width={'56px'} type={'text'} value={dddphone} onChange={(e) => setDddphone(justNumber(e.target.value))} charLimit={2} />
                                            </LabelInput>
                                            <LabelInput text={'Telefone'}>
                                                <Input width={'255px'} type={'text'} value={phone} onChange={(e) => setPhone(maskPhone(e.target.value))} charLimit={9} />
                                            </LabelInput>
                                        </div>
                                        <LabelInput text={'CEP'}>
                                            <Input type={'text'} value={maskCep(cep)} id='cep' onChange={(e) => setCep(e.target.value)} charLimit={9} />
                                        </LabelInput>
                                        <LabelInput text={'Estado *'}>
                                            <InputAutoComplete
                                                preSelectedValue={addressState?.nome?.length > 0 ? addressState?.nome : ''}
                                                selectedLabel={'nome'} id='addressState'
                                                data={addressStateList} optionList={['nome']}
                                                onChange={e => {
                                                    {
                                                        setAddressState(e)
                                                        e?.nome !== undefined && getCityApi(e)
                                                    }
                                                }}>
                                            </InputAutoComplete>
                                        </LabelInput>
                                        {
                                            !addressState?.nome ?
                                                <LabelInput text={'Cidade *'}>
                                                    <Input type={'text'} value={addressCity} id='addressCity' disabled={true} />
                                                </LabelInput>
                                                :
                                                <LabelInput text={'Cidade *'}>
                                                    <InputAutoComplete
                                                        preSelectedValue={addressCity ? addressCity?.nome : addressCityList?.nome}
                                                        selectedLabel={'nome'} id='addressCity'
                                                        data={addressCityList} optionList={['nome']}
                                                        onChange={e => {
                                                            setAddressCity(e)
                                                        }}>
                                                    </InputAutoComplete>
                                                </LabelInput>
                                        }
                                        <LabelInput text={'Bairro'}>
                                            <Input type={'text'} value={addressDistrict} onChange={(e) => setAddressDistrict(e.target.value)} charLimit={255} />
                                        </LabelInput>
                                        <LabelInput text={'Logradouro'}>
                                            <Input type={'text'} value={addressRoad} onChange={(e) => setAddressRoad(e.target.value)} charLimit={255} />
                                        </LabelInput>
                                        <LabelInput text={'Número'}>
                                            <Input type={'text'} id='addressNumber' value={addressNumber} onChange={(e) => setAddressNumber(justNumber(e.target.value))} charLimit={8} />
                                        </LabelInput>
                                        <LabelInput text={'Complemento'}>
                                            <Input type={'text'} value={addressComplement} id='addressComplement' onChange={(e) => setAddressComplement(e.target.value)} charLimit={100} />
                                        </LabelInput>
                                        <LabelInput text={'Tipo *'}>
                                            <InputAutoComplete preSelectedValue={selectedPartnerCompanyType.name} selectedLabel={'name'} id={'partnerType'} data={partnerType} optionList={['name']} onChange={e => setSelectedPartnerCompanyType(e)} >
                                            </InputAutoComplete>
                                        </LabelInput>
                                        {
                                            selectedPartnerCompanyType?.id === 2 &&
                                            <LabelInput text={'Selecione a matriz *'}>
                                                <InputAutoComplete
                                                    preSelectedValue={master_id ? listMatriz.filter(item => item?.id == master_id)[0].name : ''}
                                                    selectedLabel={'name'} id='master_id'
                                                    data={listMatriz} optionList={['name']}
                                                    onChange={e => {
                                                        setMaster_id(e?.id)
                                                    }}>
                                                </InputAutoComplete>
                                            </LabelInput>
                                        }
                                        <div className='flex flex-col relative'>
                                            <LabelInput text={'Agenda fixa *'}>
                                                <InputAutoComplete
                                                    preSelectedValue={selectedSchedulesType?.description}
                                                    selectedLabel={'name'} id={'schedulesType'}
                                                    data={fixedHoursSchedule}
                                                    optionList={['description']}
                                                    onChange={e => setSelectedSchedulesType(e)} >
                                                </InputAutoComplete>
                                            </LabelInput>
                                            <Tippy content={<span>Defina o tempo máximo da agenda desse parceiro por atendimento, marque livre para que o operador que irá agendar possa escolher o horáro final</span>}
                                                arrow={true}
                                                animation='perspective'
                                                placement='top'
                                                delay={100}>

                                                <div className='absolute right-2'>
                                                    <FaQuestionCircle />
                                                </div>

                                            </Tippy>
                                        </div>
                                        <div className='flex flex-col relative'>
                                            <LabelInput text={'Bloquear agenda'}>
                                                <InputAutoComplete
                                                    preSelectedValue={options.find(item => item?.value == selectedPartnerBlock).description}
                                                    selectedLabel={'description'} 
                                                    id={'partnerBlock'}
                                                    data={options}
                                                    optionList={['description']}
                                                    onChange={e => {
                                                        if (typeof(e) == 'object'){
                                                            setSelectedPartnerBlock(e?.value)
                                                        }
                                                    }}>
                                                </InputAutoComplete>
                                            </LabelInput>
                                            <Tippy content={<span>Defina se a agenda do parceiro vai ser bloqueada, caso não atualize os agendamentos em 7 dias.</span>}
                                                arrow={true}
                                                animation='perspective'
                                                placement='top'
                                                delay={100}>

                                                <div className='absolute right-2'>
                                                    <FaQuestionCircle />
                                                </div>

                                            </Tippy>
                                        </div>
                                        <LabelInput text={'Atende online *'}>
                                            <InputAutoComplete
                                                preSelectedValue={options?.find(item => item?.value == selectedIsRemote).description}
                                                value={selectedIsRemote ? options.find(item => item?.value == selectedIsRemote).description : ''}
                                                selectedLabel={'description'} 
                                                id={'selectedIsRemote'}
                                                data={options}
                                                optionList={['description']}
                                                onChange={e => {
                                                    if (typeof(e) == 'object'){
                                                        setSelectedIsRemote(e?.value)
                                                    }
                                                }}>
                                            </InputAutoComplete>
                                        </LabelInput>
                                    </form>
                                    {
                                        listHoursId.length > 0 ? (
                                            <>

                                                <br />
                                                <br />
                                                {
                                                    listHoursId.map((item) => {
                                                        return (

                                                            <>

                                                                <div className='hidden lg:flex flex-col lg:flex-row gap-2  rounded-t-2xl items-center justify-between pt-1 px-36 lg:w-[65rem]  h-8 dark:shadow-secondaryDefaultDark bg-primaryDefaultLight'>
                                                                    <label className='text-white'>Dia da Semana</label>
                                                                    <label className='text-white'>Horário Inicio</label>
                                                                    <label className='text-white'> Horário Fim </label>
                                                                </div>

                                                                <div className='lg:pl-10 mb-10 flex flex-col lg:flex-row rounded-b-2xl gap-2 items-center justify-start lg:w-[65rem] dark:shadow-secondaryDefaultDark dark:bg-primaryDefaultDark lg:bg-white lg:h-24 lg:shadow-xl'>
                                                                    <LabelInput text={'Dia da Semana'}>
                                                                        <InputAutoComplete
                                                                            selectedLabel={'name'}
                                                                            data={weekday} optionList={['name']}
                                                                            value={weekday.filter(day => day?.id == hours[item].weekday)[0]?.name}
                                                                            id={`${item} - weekday`}
                                                                            onChange={(e) => {
                                                                                hours[item].weekday = e?.id
                                                                                setHours(hours)
                                                                                setListHoursId([...listHoursId])
                                                                            }} charLimit={100} width={'318px'}
                                                                        />
                                                                    </LabelInput>
                                                                    <LabelInput text={'Horário Inicio'}>
                                                                        <InputAutoComplete
                                                                            selectedLabel={'name'}
                                                                            data={listHours} optionList={['name']}
                                                                            id={`${item} - startHour`}
                                                                            value={hours[item].startHour ? listHours.filter(hour => hour?.id == hours[item].startHour)[0]?.name : ''}
                                                                            onChange={(e) => {
                                                                                hours[item].startHour = e?.id
                                                                                setHours(hours)
                                                                                setListHoursId([...listHoursId])
                                                                            }} charLimit={100} width={'318px'}
                                                                        />
                                                                    </LabelInput>
                                                                    <LabelInput text={'Horário Fim'}>

                                                                        <InputAutoComplete
                                                                            selectedLabel={'name'}
                                                                            data={listHours} optionList={['name']}
                                                                            id={`${item} - finishHour`}
                                                                            value={hours[item].finishHour ? listHours.filter(hour => hour?.id == hours[item].finishHour)[0]?.name : ''}
                                                                            onChange={(e) => {
                                                                                hours[item].finishHour = e?.id
                                                                                setHours(hours)
                                                                                setListHoursId([...listHoursId])
                                                                            }} charLimit={100} width={'318px'}
                                                                        />
                                                                    </LabelInput>
                                                                    <div className='flex flex-row gap-3 mt-4 lg:translate-x-11 lg:-translate-y-2'>
                                                                        <Tippy content={<span>Adicionar</span>}
                                                                            arrow={true}
                                                                            animation='perspective'
                                                                            placement='top'
                                                                            delay={100}>
                                                                            <div>
                                                                                <FiPlus className='text-secondaryDefaultLight dark:secondaryDefaultLight text-3xl cursor-pointer' onClick={() => addHour()} />
                                                                            </div>
                                                                        </Tippy>
                                                                        <Tippy content={<span>Remover</span>}
                                                                            arrow={true}
                                                                            animation='perspective'
                                                                            placement='top'
                                                                            delay={100}>
                                                                            <div>
                                                                                <FiMinus className='text-secondaryDefaultLight dark:secondaryDefaultLight text-3xl cursor-pointer' approval={true} shadow={true} onClick={() => removeHour(item)} />
                                                                            </div>
                                                                        </Tippy>
                                                                        <Tippy content={<span>Duplicar</span>}
                                                                            arrow={true}
                                                                            animation='perspective'
                                                                            placement='top'
                                                                            delay={100}>
                                                                            <div>
                                                                                <FiCopy className='text-secondaryDefaultLight dark:secondaryDefaultLight text-2xl cursor-pointer' onClick={(e) => addHour(e)} />
                                                                            </div>
                                                                        </Tippy>
                                                                    </div>

                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }

                                            </>
                                        ) : (
                                            <div className='flex flex-col justify-center items-center w-full gap-1 mt-8'>
                                                <div className='flex flex-col text-white gap-1 items-center  mt-8 left-28'>
                                                    <LabelInput>
                                                        <Title text={'Adicionar Horário'} />
                                                    </LabelInput>
                                                    <FiPlus className='text-secondaryDefaultLight dark:secondaryDefaultLight text-3xl cursor-pointer' onClick={() => addHour()} />
                                                </div>
                                            </div>

                                        )
                                    }


                                </div>
                            </>
                    }
                </div>

            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button
                        id='cancelUserEdit'
                        approval={false}
                        shadow={true}
                        onClick={() => { navigate('/partners'); setShowModificationModal(false) }}
                    >Voltar
                    </Button>
                    <Button
                        id='approvalUserEdit'
                        approval={true}
                        shadow={true}
                        onClick={() => handleSubmit()}
                    >Salvar
                    </Button>
                </div>
            </Footer>
        </Container>
    )
}