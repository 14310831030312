import React, { useState } from 'react'
import { useEffect } from 'react'
import { useAuth } from '../../contexts/useAuth'
import { FaArrowDown, FaArrowUp, FaSearch } from 'react-icons/fa'
import { RiArrowUpDownLine } from 'react-icons/ri'
import { api } from '../../services/api/api'
import { formattTimeToShow } from '../../utils/formatData/formatTimeToShow'
import { DefaultLoader } from '../../components/loaders/defaultLoader'

export function TablePaginationDefault({ collumns, onClick, width, customFunctions, cursorPointer, apiUrl, filters, quantity, mobileFunctions, colSpan = 6 }) {

    const { userData } = useAuth()
    const [sortBy, setSortBy] = useState('')
    const [loading, setLoading] = useState(true)
    const [sortByDirection, setSortByDirection] = useState(true)
    const [selectedPage, setSelectedPage] = useState(1)
    const [tableData, setTableData] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [lastFilter, setlastFilter] = useState(0)

    useEffect(() => {

        async function getData(){
            try {
                setLoading(true)
                
                const urlContructor = [``]
                let page = selectedPage
                if(lastFilter != JSON.stringify(filters)) {
                    page = 1
                    setSelectedPage(page)
                }
                urlContructor.push(`page=${page}`)

                if(filters){
                    Object.keys(filters).map(key => {
                        if(![null, undefined, '', NaN].includes(filters[key])){
                            urlContructor.push(`${key}=${filters[key]}`)
                        }
                    })
                }
                
                if(sortBy){
                    urlContructor.push(`orderBy=${sortBy}&orderByDirection=${sortByDirection ? 'ASC' : 'DESC'}`)
                }
    
                const data = await api.get(apiUrl + '?' + urlContructor.join('&'), {
                    headers: {
                        authorization: `Bearer ${userData.token}`
                    }
                })
                console.log(data.data.data)

                setTableData(data.data.data)
                setTotalPages(data.data.totalPages)
                quantity(data.data.quantity)
                setlastFilter(JSON.stringify(filters))

                setLoading(false)
            } catch (error) {
                setLoading(false)
            }

        }

        getData()

    }, [filters, apiUrl, selectedPage, sortByDirection, sortBy])

    return (
        loading ? <DefaultLoader/> 
        : (
            <>
                {/* TABLE DEFAULT - NORMAL */}
                <div class='overflow-x dark:border dark:border-secondaryBorderDark hidden sm:flex flex-col items-center justify-center w-full mt-3 boxShadow  overflow-y-auto rounded-lg'>
                    <table className='border-collapse table-auto overflow-y-auto w-full '>
                        <thead>
                            <tr>
                                {
                                    collumns?.map((titleColumn, indexTh) => {
                                        return <th onClick={(e) => { 
                                            setSortByDirection(!sortByDirection); 
                                            setSortBy(titleColumn?.name);
                                        }} key={indexTh} className='bg-primaryDefaultLight text-sm dark:bg-thirdDefaultDark text-white font-extrabold h-14 sticky'>
                                            <div className='flex flex-row items-center justify-center w-full'>
                                                <p className='flex gap-2 cursor-pointer w-fit'>
                                                    {titleColumn?.description}
                                                    {
                                                        sortBy !== titleColumn?.name ?
                                                            <RiArrowUpDownLine size={'20'} />
                                                        : sortByDirection ?                                                     
                                                            <FaArrowUp size={'14'} />    
                                                        :
                                                            <FaArrowDown size={'14'} />
                                                    }
                                                </p>
                                            </div>
                                        </th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody className=''>
                            {
                                tableData?.length === 0 ?
                                    <tr key={'1'} className='hover:bg-hoverLight dark:hover:bg-hoverDark transition-all duration-200'>
                                        <td colSpan={collumns.length} key={`1-1`} className={`text-sm ${!cursorPointer ? 'cursor-pointer' : ''} text-center text-titleBlackTextLight dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}>Sem registros</td>
                                    </tr>
                                : 
                                tableData?.map((apiData, index) => {
                                    return (
                                        <tr key={index} className='hover:bg-hoverLight dark:hover:bg-hoverDark transition-all duration-200'>
                                            {
                                                collumns?.map((collumn, indexCollum) => {
                                                    return (customFunctions?.isValid && customFunctions?.collumn == indexCollum) ?
                                                    <td 
                                                        key={`${indexCollum}-${index}`}
                                                        className={`text-sm ${!cursorPointer ? 'cursor-pointer' : ''} text-center text-gray-900 dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}
                                                    >
                                                        {collumn.type == 'date' ? formattTimeToShow(apiData[collumn.name]) : collumn?.func ? collumn?.func(apiData) : apiData[collumn.name]}
                                                    </td>
                                                    :
                                                    <td 
                                                        key={`${indexCollum}-${index}`} 
                                                        onClick={() => onClick(apiData)} 
                                                        className={`text-sm ${!cursorPointer ? 'cursor-pointer' : ''} text-center text-gray-900 dark:text-titleGrayTextDark h-10 border-b border-gray-300 dark:border-primaryBorderDark`}
                                                    >
                                                        {collumn.type == 'date' ? formattTimeToShow(apiData[collumn.name]) : collumn?.func ? collumn?.func(apiData) : apiData[collumn.name]}
                                                    </td>
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        <tfoot>
                            <tr className='bg-bgSecondaryLight dark:bg-thirdDefaultDark py-1'>
                                <th colSpan={colSpan}>
                                    <div className='w-full flex flex-row items-center justify-end px-4'>
                                        {
                                            [...Array(totalPages)?.keys()].map((thisPage, index) => {

                                                const buttonTrue = <a onClick={() => setSelectedPage(thisPage+1)} className='text-white px-2 bg-primaryDefaultLight hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage+1}</a>
                                                const buttonFalse = <a onClick={() => setSelectedPage(thisPage+1)} className='px-2 hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage+1}</a>
                                                let existsPoints = false
                                                let showPointers = false

                                                if (totalPages < 6) {
                                                    return selectedPage === thisPage + 1 ? buttonTrue : buttonFalse
                                                } else {
                                                    existsPoints = true
                                                    if (index + 1 === 1 && selectedPage !== thisPage + 1) {
                                                        return buttonFalse
                                                    } else if (index + 1 == thisPage + 1 && selectedPage === thisPage + 1) {
                                                        return buttonTrue
                                                    } else if (selectedPage + 3 > index + 1 && selectedPage - 2 < index + 1) {
                                                        return buttonFalse
                                                    } else if (totalPages === thisPage + 1) {
                                                        return buttonFalse
                                                    } else {
                                                        if (selectedPage + 3 < index + 1 || selectedPage - 2 > index + 1) {
                                                            showPointers = true
                                                        }
                                                        if (existsPoints && showPointers == false) {
                                                            return <a>...</a>
                                                        }
                                                    }
                                                }
                                            })
                                        }
                                    </div>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div >
                {/* TABLE DEFAULT - MOBILE */}
                <div className='flex flex-col w-full gap-2 h-full p-2 sm:hidden items-center justify-center overflow-y-auto'>
                    {
                        tableData.map((apiData, index) => {
                            return (
                                <div key={index} className='flex flex-col h-full w-full pb-4'>
                                    <div className='flex flex-col w-full boxShadow mt-2'>
                                        <div className='flex flex-col w-full'>
                                            {
                                                collumns.map((titleColumn, indexMobile) => {
                                                    return <div key={`${index}-${indexMobile}`} className='flex flex-row w-full'>
                                                        <div className='h-12 flex flex-col w-32 bg-primaryDefaultLight dark:bg-thirdDefaultDark text-bgPrimaryLight font-extrabold justify-center items-start pl-2'>
                                                            <a className='text-sm font-extrabold text-bgPrimaryLight dark:text-titleGrayTextLight'>{titleColumn?.description}</a>
                                                        </div>
                                                        <div className='h-12 w-full flex flex-col justify-center items-start'>
                                                            <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark pl-2'>
                                                                {
                                                                    titleColumn.type == 'date' 
                                                                        ? formattTimeToShow(apiData[titleColumn?.name]) 
                                                                        : titleColumn?.func 
                                                                            ? titleColumn?.func(apiData) 
                                                                            : apiData[titleColumn?.name]?.toString()?.length > 30 
                                                                                ? apiData[titleColumn?.name]?.toString()?.slice(0,30)?.concat('...') 
                                                                                : apiData[titleColumn?.name]?.toString()
                                                                }
                                                            </a>
                                                        </div>

                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className='w-full bg-primaryDefaultLight dark:bg-thirdDefaultDark h-12 flex flex-row items-center justify-start pl-2 gap-10 border-t border-primebg-primaryDefaultLight'>
                                            <div>
                                                <a className='text-sm text-bgPrimaryLight font-extrabold'>Opções</a>
                                            </div>
                                            {mobileFunctions.map((func, indexFuncIcon) => {
                                                return <div key={`${index}-${indexFuncIcon}-`} onClick={()=> onClick({data: apiData, func: func})} className='flex flex-col items-center justify-center cursor-pointer h-full w-12' title={func.title}>
                                                    <div className='text-2xl text-bgPrimaryLight'>{func.icon}</div>                                        
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='p-4 flex'>
                        {
                            [...Array(totalPages)?.keys()].map((thisPage, index) => {

                                const buttonTrue = <a onClick={() => setSelectedPage(thisPage+1)} className='text-white px-2 bg-primaryDefaultLight hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage+1}</a>
                                const buttonFalse = <a onClick={() => setSelectedPage(thisPage+1)} className='px-2 hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage+1}</a>
                                let existsPoints = false
                                let showPointers = false

                                if (totalPages < 6) {
                                    return selectedPage === thisPage + 1 ? buttonTrue : buttonFalse
                                } else {
                                    existsPoints = true
                                    if (index + 1 === 1 && selectedPage !== thisPage + 1) {
                                        return buttonFalse
                                    } else if (index + 1 == thisPage + 1 && selectedPage === thisPage + 1) {
                                        return buttonTrue
                                    } else if (selectedPage + 3 > index + 1 && selectedPage - 2 < index + 1) {
                                        return buttonFalse
                                    } else if (totalPages === thisPage + 1) {
                                        return buttonFalse
                                    } else {
                                        if (selectedPage + 3 < index + 1 || selectedPage - 2 > index + 1) {
                                            showPointers = true
                                        }
                                        if (existsPoints && showPointers == false) {
                                            return <a>...</a>
                                        }
                                    }
                                }
                            })
                        }
                    </div>
                </div>
            </>
        )
            
    )
}