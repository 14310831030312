import { FaUserTie, FaPencilAlt, FaWrench, FaUsers, FaUserCheck, FaTags } from "react-icons/fa"

export const configItemsPartner = [
    {
        "icon": <FaUsers/>,
        "name": "Usuários",
        "permission": [5],
        "subItems": [
            {
                "path": "/usersPartners",
                "name": "Meus Usuários",
                "permission": [5],
            },
        ]
    },
    {
        "icon": <FaTags/>,
        "name": "Tags",
        "permission": [5],
        "subItems": [
            {
                "path": "/labels",
                "name": "Etiquetas",
                "permission": [5,6],
            }
        ]
    },
    {
        "icon": <FaWrench/>,
        "name": "Configurações",
        "permission": [1, 2 , 3, 4, 5, 6, 7],
        "subItems": [
            {
                "path": "/theme",
                "name": "Meu tema",
                "permission": [1, 2, 3, 4, 5, 6,7],
            },
        ]
    },
]