import React, { useEffect } from 'react'
import { Route, Routes, HashRouter, Navigate } from 'react-router-dom'
import { CalendarMain } from '../components/calendar/calendar.main'
import { ProtectedRoute } from '../pages/privateRoute'
import { Menu } from '../components/menu/menu'
import { useAuth } from '../contexts/useAuth'
import { ScheduleContextProvider } from '../contexts/schedulesContext'
import { SocketProvider } from '../contexts/socketContext'

import { ClientsCreate } from '../pages/configuration/clients/clients.create'
import { ClientsTable } from '../pages/configuration/clients/clients.table'
import { Configuration } from '../pages/configuration/configurationHome/configuration.home'

import { Customer } from '../pages/configuration/customer/customer.profile'

import { ReservationCategoryCreate } from '../pages/configuration/reservations/reservartion.category.create'
import { ReservationCategory } from '../pages/configuration/reservations/reservation.category'

import { ThemesCenter } from '../pages/configuration/themes/themes.center'

import { UserEdit } from '../pages/configuration/users/user.edit'
import { UserCreate } from '../pages/configuration/users/users.creation'
import { UsersTable } from '../pages/configuration/users/users.table'

import { Home } from '../pages/home'
import { Reservation } from '../pages/reservation/reservation'
import { ThemesDefaultCenter } from '../pages/configuration/themes/themes.default.center'
import { CitiesStates } from '../pages/configuration/cities/citiesStates.create'

import { PartnersCreate } from '../pages/configuration/partners/partners.create'
import { PartnersEdit } from '../pages/configuration/partners/partners.edit'
import { Partners } from '../pages/configuration/partners/partners'

import { UsersPartnersTable } from '../pages/configuration/usersPartners/usersPartners.table'
import { UsersPartnersCreate } from '../pages/configuration/usersPartners/usersPartners.create'
import { UsersPartnersEdit } from '../pages/configuration/usersPartners/usersPartners.edit'

import { PartnersUsers } from '../pages/configuration/partners/partners.users'
import { MyProfile } from '../pages/configuration/myProfile/myProfile'
import { ClientsEdit } from '../pages/configuration/clients/clients.edit'
import { ToSchedule } from '../pages/schedule/schedule'
import { OperatorSchedule } from '../pages/schedule/operator.schedule'
import { SchedulePartnersTable } from '../pages/schedulePartners/schedulePartnersTable'
import { OperatorScheduleCalendarView } from '../pages/schedule/operator.schedule.calendarView'
import { PartnerSchedule } from '../pages/partnerSchedule/partner.schedule'
import { PartnerScheduleCalendarView } from '../pages/partnerSchedule/partner.schedule.calendarView'

import { LinkUsers } from '../pages/configuration/linkUsers/linkUsers'
import { LinkOperator } from '../pages/configuration/linkOperator/linkOperator'
import { LinkConsultant } from '../pages/configuration/linkConsultant/linkConsultant'
import { Report } from '../pages/reports/report'
import { ReportPartners } from '../pages/reports/reportPartners'

import { BlockSchedule } from '../pages/configuration/blockSchedule/blockSchedule'
import { BlockScheduleManyPartners } from '../pages/configuration/blockSchedule/blockScheduleManyPartners'

import { TagsTable } from '../pages/configuration/tags/tags.table'
import { TagsCreate } from '../pages/configuration/tags/tags.create'
import { TagsEdit } from '../pages/configuration/tags/tags.edit'

import { ConfirmationTable } from '../pages/configuration/confirmation/confirmation.table'
import { ConfirmationCreate } from '../pages/configuration/confirmation/confirmation.create'
import { ConfirmationEdit } from '../pages/configuration/confirmation/confirmation.edit'

import { LabelsTable } from '../pages/configuration/labels/labels.table'
import { LabelsCreate } from '../pages/configuration/labels/labels.create'
import { LabelsEdit } from '../pages/configuration/labels/labels.edit'

import { TransferClientsPartner } from '../pages/configuration/transferClients/transferClientsPartner'

export function AppRoutes() {

    const { userData, loadingUniversal } = useAuth()

    return (

        <>

            {
                loadingUniversal ?
                    <>
                    </>
                    :
                    <HashRouter>
                        <div className='flex flex-col-reverse sm:flex-row md:h-auto justify-between md:justify-items-start overflow-hidden'>
                            <SocketProvider>
                                <ScheduleContextProvider>
                                    <Menu />
                                    <Routes>
                                        <Route path='/' element={<Home />} />
                                        <Route path='/configuration' element={<Configuration />} />
                                        <Route path="/users" element={<ProtectedRoute user={userData}>
                                            <UsersTable />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/userCreate" element={<ProtectedRoute user={userData}>
                                            <UserCreate />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/usersEdit/:id" element={<ProtectedRoute user={userData}>
                                            <UserEdit />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/clients" element={<ProtectedRoute user={userData} permission={[2, 3, 4, 5, 6, 7]}>
                                            <ClientsTable />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/clientsCreate" element={<ProtectedRoute user={userData} permission={[2, 3, 4, 5, 6, 7]}>
                                            <ClientsCreate defaultRoute={true} />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/clientsEdit/:id" element={<ProtectedRoute user={userData} permission={[2, 3, 4, 5, 6, 7]}>
                                            <ClientsEdit />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/partners" element={<ProtectedRoute user={userData} permission={[4]}>
                                            <Partners />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/partnersCreate" element={<ProtectedRoute user={userData} permission={[4]}>
                                            <PartnersCreate />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/partnersEdit/:id" element={<ProtectedRoute user={userData} permission={[4]}>
                                            <PartnersEdit />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/usersPartners" element={<ProtectedRoute user={userData} permission={[4, 5]}>
                                            <UsersPartnersTable />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/usersPartnersCreate" element={<ProtectedRoute user={userData} permission={[4]}>
                                            <UsersPartnersCreate />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path='/usersPartnersEdit/:id' element={<UsersPartnersEdit user={userData} permission={[4, 5]}>
                                            <PartnersEdit />
                                        </UsersPartnersEdit>}>
                                        </Route>
                                        <Route path="/operatorSchedule" element={<ProtectedRoute user={userData} permission={[2, 3, 4]}>
                                            <OperatorSchedule />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/operatorScheduleCalendar" element={<ProtectedRoute user={userData} permission={[2,3]}>
                                            <OperatorScheduleCalendarView />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/operatorSchedulePartner" element={<ProtectedRoute user={userData} permission={[5, 6, 7]}>
                                            <PartnerSchedule />
                                        </ProtectedRoute>} />
                                        <Route path="/operatorSchedulePartnerCalendar" element={<ProtectedRoute user={userData} permission={[5, 6, 7]}>
                                            <PartnerScheduleCalendarView />
                                        </ProtectedRoute>} />
                                        <Route path="/schedulesPartners" element={<ProtectedRoute user={userData} permission={[5,6]}>
                                            <SchedulePartnersTable />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/linkUsers" element={<ProtectedRoute user={userData} permission={[2]}>
                                            <LinkUsers />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/linkOperator" element={<ProtectedRoute user={userData} permission={[2]}>
                                            <LinkOperator />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/linkConsultant" element={<ProtectedRoute user={userData} permission={[2]}>
                                            <LinkConsultant />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/blockSchedule/:id" element={<ProtectedRoute user={userData} permission={[1]}>
                                            <BlockSchedule />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/blockScheduleMany" element={<ProtectedRoute user={userData} permission={[1,2]}>
                                            <BlockScheduleManyPartners />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/tags" element={
                                            <ProtectedRoute user={userData} permission={[1,2]}>
                                                <TagsTable />
                                            </ProtectedRoute>
                                        }></Route>
                                        <Route path="/tagsCreate" element={
                                            <ProtectedRoute user={userData} permission={[1,2]}>
                                                <TagsCreate />
                                            </ProtectedRoute>
                                        }></Route>
                                        <Route path="/tagsEdit/:id" element={
                                            <ProtectedRoute user={userData} permission={[1,2]}>
                                                <TagsEdit />
                                            </ProtectedRoute>
                                        }></Route>
                                        <Route path="/confirmation" element={
                                            <ProtectedRoute user={userData} permission={[1,2,5,6]}>
                                                <ConfirmationTable />
                                            </ProtectedRoute>
                                        }></Route>
                                        <Route path="/confirmationCreate" element={
                                            <ProtectedRoute user={userData} permission={[1,2,5,6]}>
                                                <ConfirmationCreate />
                                            </ProtectedRoute>
                                        }></Route>
                                        <Route path="/confirmationEdit/:id" element={
                                            <ProtectedRoute user={userData} permission={[1,2,5,6]}>
                                                <ConfirmationEdit />
                                            </ProtectedRoute>
                                        }></Route>
                                        <Route path="/labels" element={
                                            <ProtectedRoute user={userData} permission={[1,2,5,6]}>
                                                <LabelsTable />
                                            </ProtectedRoute>
                                        }></Route>
                                        <Route path="/labelsCreate" element={
                                            <ProtectedRoute user={userData} permission={[1,2,5,6]}>
                                                <LabelsCreate />
                                            </ProtectedRoute>
                                        }></Route>
                                        <Route path="/labelsEdit/:id" element={
                                            <ProtectedRoute user={userData} permission={[1,2,5,6]}>
                                                <LabelsEdit />
                                            </ProtectedRoute>
                                        }></Route>
                                        <Route path="/transferClients" element={
                                            <ProtectedRoute user={userData} permission={[5]}>
                                                <TransferClientsPartner />
                                            </ProtectedRoute>
                                        }></Route>
                                        <Route path="/toschedule" element={<ProtectedRoute user={userData}>
                                            <ToSchedule />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path='/myProfile' element={<MyProfile />} />
                                        <Route path='/reservation' element={<Reservation />} />
                                        <Route path='/reservationCategory' element={<ReservationCategory />} />
                                        <Route path='/reservationCategoryCreate' element={<ReservationCategoryCreate />} />
                                        <Route path='/calendar' element={<CalendarMain />} />
                                        <Route path='/theme' element={<ThemesCenter />} />
                                        <Route path='/defaultTheme' element={
                                            <ProtectedRoute user={userData} func={''}>
                                                <ThemesDefaultCenter />
                                            </ProtectedRoute>
                                        } />

                                        <Route path="/reports" element={<ProtectedRoute user={userData} permission={[2, 3, 4, 6, 7]}>
                                            <Report />
                                        </ProtectedRoute>}>
                                        </Route>
                                        <Route path="/reportsPartners" element={<ProtectedRoute user={userData} permission={[5,6]}>
                                            <ReportPartners />
                                        </ProtectedRoute>}>
                                        </Route>
                                    </Routes>
                                </ScheduleContextProvider>
                            </SocketProvider>
                        </div>
                    </HashRouter>
            }

        </>
    )
}