// hooks
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// components
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Toggle } from '../../../components/toggle/toggleButton'
// services
import { api } from '../../../services/api/api'
// utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { Footer } from '../../../components/footer/Footer'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/perspective.css'

export function ConfirmationEdit() {
    const { userData } = useAuth()
    const navigate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const [updatedUser, setUpdatedUser] = useState(false)
    const [status, setStatus] = useState(false)
    const [activeUsers, setActiveUsers] = useState(false)

    //selectedVariables
    const [selectedDescription, setSelectedDescription] = useState('')

    useEffect(() => {

        async function getConfirmation() {
            setLoading(true)

            try {

                const getConfirmationAPI = await api.get(`/api/v1/confirmation/${id}`, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`
                    }
                })

                if (userData?.partners_id && userData?.partners_id != getConfirmationAPI?.data?.data?.partners_id){
                    return navigate('/')
                }
                
                setSelectedDescription(getConfirmationAPI?.data?.data?.description)
                setStatus(getConfirmationAPI?.data?.data?.active)

                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        getConfirmation()

    }, [updatedUser])

    async function handleSubmit() {

        const requiredFields =
            [
                { name: 'description', value: selectedDescription, required: true, type: 'string' },
            ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Campos obrigatórios não preenchidos!')
            return setShowNotificationModalSuccess(false)
        }

        setLoading(true)

        try {

            await api.put(`/api/v1/confirmation/${id}`, {
                description: selectedDescription ? selectedDescription : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/confirmation')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDelete() {

        setLoading(true)

        try {

            await api.delete(`/api/v1/confirmation/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/confirmation')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

    }

    async function handleAlterStatus() {

        const newStatus = !status
        setStatus(newStatus)

        try {

            await api.put(`/api/v1/confirmation/${id}/active/${newStatus ? 1 : 0}`, {}, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })

            setShowNotificationModalSuccess(true)
            setShowModificationModal(true)
            setShowNotificationModalText(newStatus === false ? `Tese desativada com sucesso.` : `Tese ativada com sucesso.`)

        } catch (error) {
            setLoading(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            }
        }
    }

    return (
        <>
            <ScreenModal title={'Exlusão de usuário'} width={400} height={200} >
                <div className='w-full p-2 h-96 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja realmente excluir a confirmação?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button shadow={true} onClick={() => setShowUniversalModal(false)} approval={false}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage >
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={`Editar Confirmaçao`} />
                    </div>
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <div className='flex flex-col lg:items-start items-center justify-start w-full'>
                                <form className='flex flex-col flex-wrap gap-4 ' onSubmit={e => e.preventDefault()}>
                                    <LabelInput text={'Descrição *'}>
                                        <textarea className='outline-none border border-gray-400 border-opacity-75 rounded-lg w-80 h-36 lg:h-32 resize-none
                                                        dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark
                                                        focus:border focus:border-primaryDefaultLight
                                                        dark:focus:border dark:focus:border-primaryDefaultLight p-2
                                                        dark:scrollbar-thumb-gray-700 dark:scrollbar-track-gray-900 scrollbar-thin scrollbar-track-gray-400 scrollbar-thumb-gray-300' 
                                            maxLength={250} 
                                            id='description'
                                            value={selectedDescription ? selectedDescription : ''}  
                                            onChange={e => setSelectedDescription(e.target.value)} 
                                        />
                                    </LabelInput>
                                    <div className='flex flex-col w-full items-center lg:items-start justify-center mt-7'>
                                        <Tippy content={<span>{status ? 'Desativar' : 'Ativar'}</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='top'
                                            delay={100}>
                                            <div>
                                                <Toggle  status={status ? true : false} onClick={(e) => handleAlterStatus()} />                                                   
                                            </div>
                                        </Tippy>
                                    </div>
                                </form>
                            </div>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button
                            id='cancelUserEdit'
                            approval={false}
                            shadow={true}
                            onClick={() => { navigate('/confirmation'); setShowModificationModal(false) }}
                        >Voltar
                        </Button>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <Button
                                id='deleteUserEdit'
                                approval={false}
                                shadow={true}
                                onClick={() => setShowUniversalModal(true)}
                            >Excluir
                            </Button>
                            <Button
                                id='approvalUserEdit'
                                approval={true}
                                shadow={true}
                                onClick={() => handleSubmit()}
                            >Editar
                            </Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )


}
