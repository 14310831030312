import React, { useEffect, useState } from "react";
//components
import { Container } from "../../components/container/container";
import { InputAutoComplete } from "../../components/input/input.autocomplete";
import { DefaultLoader } from "../../components/loaders/defaultLoader";
import { Button } from "../../components/buttons/button.default";
import { CalendarMain } from "../../components/calendar/calendar.main";
import { NotificationModal } from "../../components/modals/notification/notification.modal";
//utils
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import { LabelInput } from "../../components/label/label.input";
import { responseError } from "../../utils/responsesFunctions/error.response";
import { getClientAttachments, getClientEspecific, getPartnersEspecific, getClientAttachmentByName } from "../../services/api/callAPIsFunctions/defaultCalls.api";
//contexts
import { useAuth } from "../../contexts/useAuth";
import { useScreenSizeContext } from "../../contexts/screenSizeContext";
import { useThemeContext } from "../../contexts/themeContext";
//serivces
import { api } from "../../services/api/api";
import { FaSave, FaTrash } from "react-icons/fa";
import { validateFields } from "../../utils/form.validator";
import { convertURLtoFile } from "../../utils/formatImages/urlToFile";

export function SchedulePartnersEdit({ selectedEvent }) {

    const { userData, setReloadData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const { screenX, screenY } = useScreenSizeContext()
    const [loading, setLoading] = useState(true)
    const [showAnimate, setShowAnimate] = useState(false)
    const [scheduleData, setScheduleData] = useState(selectedEvent)
    const [confirmationList, setConfirmationList] = useState([])
    const [selectedClient, setSelectedClient] = useState([])
    const [selectedPartner, setSelectedPartner] = useState([])
    const [selectedDay, setSelectedDay] = useState(moment(selectedEvent?.scheduleDate, 'YYYY-MM-DD').utc(false))
    const [selectedConfirmation, setSelectedConfirmation] = useState('')
    const [scheduleAttacments, setScheduleAttachments] = useState([])
    const [labels, setLabels] = useState([])
    const [selectedLabels, setSelectedLabels] = useState([])
    const [selectedLabelDescription, setSelectedLabelDescription] = useState('')
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        function cleanState() {
            setSelectedClient('')
            setSelectedPartner('')
            setSelectedConfirmation('')
        }

        cleanState()
    }, [showUniversalModal])

    async function getData() {
        try {
            setLoading(true)

            const clientData = await getClientEspecific(userData.token, scheduleData?.clients_id)
            setSelectedClient(clientData?.data[0])

            const partnersData = await getPartnersEspecific(userData.token, scheduleData?.partners_id)
            setSelectedPartner(partnersData?.data[0])

            const scheduleAttach = await getClientAttachments(userData.token, scheduleData.clients_id)
            setScheduleAttachments(scheduleAttach.data)

            const getScheduleLabels = await api.get(`/api/v1/scheduleLabels?schedulePartners_id=${scheduleData.id}`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setSelectedLabels(getScheduleLabels?.data?.data || [])

            await getLabelsAndConfirmation(scheduleData?.partners_id)

            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function getLabelsAndConfirmation(partners_id) {
        let params = []
        params.push('active=true')
        partners_id && params.push(`partners_id=${partners_id}`)
        params = params.join('&')

        try {
            const getConfirmationAPI = await api.get(`/api/v1/confirmation?active=true`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setConfirmationList(getConfirmationAPI?.data?.data.map(item => {
                return {
                    ...item,
                    inputLabel: `${item?.id} - ${item?.description}`
                }
            }))
            setSelectedConfirmation(getConfirmationAPI?.data?.data?.find(item => item?.id == scheduleData?.confirmation_id))

            const getLabelsAPI = await api.get('/api/v1/labels?' + params, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            const formatLabels = getLabelsAPI?.data?.data.map(item => {
                return {
                    ...item,
                    inputLabel: `#${item?.description}`
                }
            })
            setLabels(formatLabels)

        } catch (error) {
            console.log(error)
        }
    }

    async function handleSubmit() {
        setLoading(true)
        animateLoading(true)

        const requiredFields = [
            { name: 'selectedConfirmation', value: selectedConfirmation, required: true, type: 'object' }
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            animateLoading(true)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        let newEvent = {
            scheduleDate: selectedDay.clone().format("YYYY-MM-DD"),
            partners_id: selectedPartner?.id,
            clients_id: selectedClient?.id,
            confirmation_id: selectedConfirmation ? selectedConfirmation?.id : undefined
        }
        try {
            const schedule = await api.put(`/api/v1/schedulePartners/${selectedEvent.id}`,
                newEvent, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            const scheduleId = schedule.data.data[0].id

            try {
                if (selectedLabels?.length > 0) {
                    await api.post('/api/v1/scheduleLabels', {
                        schedulePartners_id: scheduleId,
                        labels: selectedLabels?.map(item => item?.id)
                    }, {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    })
                } else {
                    await api.delete(`/api/v1/scheduleLabels/schedulePartners_id/${scheduleId}`, {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    })
                }
            } catch (error) {
                console.log('Erro ao vincular etiquetas', error)
            }

            setShowUniversalModal(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            setReloadData(true)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            animateLoading(true)
            setShowModificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Erro inesperado')
        }
    }

    async function animateLoading(status) {

        if (status) {
            setShowAnimate(true)
            await new Promise(resolve => setTimeout(resolve, 30))
        } else {
            setShowAnimate(false)
        }
    }

    async function resolveAndDownloadBlob(response, nameFile, originalName, id) {
        let attach = []
        let extension = originalName?.slice(nameFile.indexOf(`.`), originalName?.length)
        let filename = `${originalName}${extension}`
        filename = decodeURI(filename)

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const file = await convertURLtoFile(url, filename)
        attach.push(
            {
                file,
                id
            }
        )
    }

    async function downloadAttachment(file) {
        const attachment = await getClientAttachmentByName(userData.token, selectedEvent.clients_id, file.nameFile)
        await resolveAndDownloadBlob(attachment, file.nameFile, file.originalName, file.id)
        const fileToDownload = URL.createObjectURL(attachment.data)

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = fileToDownload;
        a.download = file.nameFile;
        a.click();
        window.URL.revokeObjectURL(fileToDownload);
    }

    async function handleDeleteEvent() {
        setLoading(true)
        try {

            await api.delete(`/api/v1/schedulePartners/${selectedEvent.id}`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowModificationModal(true)
            setShowUniversalModal(false)
            setReloadData(true)
            return setShowNotificationModalText(`Agendamento descartado com sucesso`)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {

                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <Container>
            <NotificationModal dontShow={true} isAuth={true} />
            <div className={`${showDeleteModal ? `opacity-1 flex` : `opacity-0 hidden`} flex-col justify-center items-center w-full h-full absolute z-[9999999993] top-0 left-0`}>
                <div className="absolute z-[9999999992] p-6 bg-white dark:bg-primaryDefaultDarkColor shadow-lg rounded-lg flex flex-col items-center justify-center">
                    {
                        loading ?
                            <DefaultLoader />
                            :
                            <>
                                <a className="mb-6 text-base text-titleBlackTextLight dark:text-titleGrayTextDark">Descartar este agendamento?</a>
                                <div className="flex flex-row gap-2 items-center justify-center">
                                    <Button shadow={true} onClick={() => handleDeleteEvent()}>Sim</Button>
                                    <Button shadow={true} onClick={() => setShowDeleteModal(false)} approval={false}>Cancelar</Button>
                                </div>
                            </>
                    }
                </div>
                <div className="w-full h-full absolute z-[999999991] opacity-75 bg-zinc-500"></div>
            </div>
            <div className="flex flex-col bg-bgPrimaryLight dark:bg-primaryDefaultDark shadow-lg overflow-auto py-6 dark:scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-track-zinc-700 scrollbar-thumb-zinc-600">
                <div style={{ width: screenX - 80 + 'px', maxWidth: '1150px', maxHeight: screenY - 200 + 'px' }} className="flex flex-col lg:w-full mx-[1.4rem] justify-start items-center flex-wrap gap-6">
                    {
                        loading ?
                            <div style={{ width: screenX - 140 + 'px', maxWidth: '1150px' }} className="flex flex-col items-center justify-center h-[30rem]">
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <div className="flex flex-row fixed top-2 left-10 items-center justify-start gap-2">
                                    <Button onClick={() => handleSubmit()}><FaSave /> Salvar</Button>
                                    <Tippy content={`Excluir agendamento`}
                                        arrow={true}
                                        animation='perspective'
                                        placement='top'
                                        zIndex={`999999999`}
                                        delay={100}>
                                        <div onClick={() => setShowDeleteModal(true)} className="cursor-pointer text-xl" >
                                            <FaTrash className="text-red-800" />
                                        </div>
                                    </Tippy>
                                </div>
                                <div className="flex flex-row items-start justify-center gap-6">
                                    <div className="flex flex-col sm:w-auto gap-4 items-center sm:items-start sm:justify-center shadow-lg border border-gray-200 dark:border-secondaryBorderDark">
                                        <div className=" flex flex-col p-2 gap-4 rounded-lg items-start justify-start py-4 h-fit max-h-[32rem] overflow-auto scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-track-gray-300 scrollbar-thumb-zinc-600">
                                            <LabelInput text={'Parceiro'} required>
                                                <InputAutoComplete
                                                    id={'selectedPartner'}
                                                    width={64}
                                                    value={selectedPartner ? selectedPartner?.name : ''}
                                                    data={[]}
                                                    disabled={true}
                                                    selectedLabel={`name`} optionList={[`id`, `name`]}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'Cliente'} required>
                                                <InputAutoComplete
                                                    id={'selectedClient'}
                                                    width={64}
                                                    preSelectedValue={selectedClient ? selectedClient?.firstname : ''}
                                                    data={[]}
                                                    disabled={true}
                                                    selectedLabel={`firstname`} optionList={[`id`, `firstname`]}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'Confirmação *'}>
                                                <InputAutoComplete
                                                    id={'selectedConfirmation'}
                                                    value={selectedConfirmation ? selectedConfirmation?.description : ''}
                                                    preSelectedValue={scheduleData?.confirmation_id ? confirmationList?.find(item => item?.id == scheduleData?.confirmation_id)?.description : ''}
                                                    width={64}
                                                    data={confirmationList}
                                                    selectedLabel={`description`}
                                                    optionList={['inputLabel']}
                                                    onChange={(e) => { setSelectedConfirmation(e) }}
                                                />
                                            </LabelInput>
                                            <div className=' dark:bg-secondaryDefaultDark bg-bgPrimaryLight lg:w-64 w-64 p-2 flex flex-col gap-4'>
                                                <CalendarMain onClick={(e) => setSelectedDay(e)} externalSelectedDay={selectedDay} />
                                            </div>
                                            <div className="flex flex-col w-64 gap-2 items-start">
                                                <input
                                                    className="
                                                    h-8 pl-2 border border-gray-400 border-opacity-75 rounded-sm outline-none
                                                    text-sm sm:text-sm transition-all duration-200 focus:shadow-borderShadow
                                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark
                                                    w-64                                                       
                                                "
                                                    placeholder="Pesquisar etiqueta"
                                                    onChange={(e) => setSelectedLabelDescription(e?.target?.value)}
                                                    value={selectedLabelDescription || ''}
                                                />
                                                <div className="max-h-20 h-20 overflow-auto w-64 flex flex-wrap border border-gray-200 shadow-inner gap-2 p-2">
                                                    {
                                                        labels?.map(label => {
                                                            const find = selectedLabels?.find(item => item?.id == label?.id)
                                                            if (find) { return null }
                                                            return label
                                                        })
                                                            ?.filter(item => item != null)
                                                            ?.filter(item => !!selectedLabelDescription ? item?.description?.toLowerCase()?.includes(selectedLabelDescription?.toLowerCase()) : item)
                                                            ?.map(label => (
                                                                <p onClick={() => setSelectedLabels(prev => ([...prev, label]))} className="h-fit py-1 px-2 text-sm border border-gray-400 cursor-pointer hover:opacity-50 duration-100 transition-all">
                                                                    #{String(label?.description)?.substring(0, 12)}
                                                                </p>
                                                            ))
                                                    }
                                                </div>
                                                <div className="flex flex-wrap border border-gray-100 w-64 h-full max-h-28 overflow-auto rounded-sm gap-2 p-2">
                                                    {
                                                        selectedLabels?.length > 0 ?
                                                            selectedLabels?.map(label => (
                                                                <p
                                                                    style={{ backgroundColor: label?.color || '#0B2E58' }}
                                                                    className={`text-sm w-fit h-fit py-1 px-2 rounded-md text-white cursor-pointer hover:opacity-75 duration-100 transition-all`}
                                                                    onClick={() => setSelectedLabels([...selectedLabels?.filter(item => item?.id != label?.id)])}
                                                                >
                                                                    #{String(label?.description).substring(0, 13)}
                                                                </p>
                                                            ))
                                                            :
                                                            <p className="text-gray-400 p-2">Etiquetas selecionadas</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" p-2 flex flex-col items-center justify-center shadow-inner">
                                        <div id="scheduleCreateData" className="flex lg:flex-row flex-col gap-4 mt-9 lg:w-full lg:items-start lg:justify-between lg:h-72" >
                                            <div className="flex flex-col items-center lg:items-start justify-center lg:w-96 rounded-lg gap-4">
                                                <div className=" border-b border-gray-200 dark:border-secondaryBorderDark w-full">
                                                    <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor">Dados do cliente</p>
                                                </div>
                                                <div className=" flex flex-col items-center justify-center mb-4">
                                                    {
                                                        selectedClient?.id > 0 &&
                                                        <div className="mt-1 flex flex-col rounded-lg gap-3 lg:w-full" >
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`Cliente: ${selectedClient?.firstname || ''}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`Cidade: ${selectedClient?.addressCity || ''}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`Estado: ${selectedClient?.addressState || ''}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`Mesoregião: ${selectedClient?.mesoregion_externalId || ''}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`Tese: ${selectedClient?.tags_description || ''}`}</a>
                                                            <div className="flex flex-col gap-1">
                                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Observação do cliente:</a>
                                                                <textarea className="text-sm dark:text-titleGrayTextDark p-2 shadow-inner h-16 max-h-16 max-w-24 lg:w-64 dark:bg-secondaryDefaultDark" disabled value={selectedClient.observation} wrap='hard'></textarea>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                            <div className="flex flex-col lg:items-center items-start lg:justify-start lg:w-96 rounded-lg gap-4 pb-11">
                                                <div className="lg:w-full border-b bordar-gray-200 dark:border-secondaryBorderDark lg:h-[1.57rem]  h-10  ">
                                                    <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor">Dados do parceiro</p>
                                                </div>
                                                <div className="mt-1 lg:w-full flex flex-col items-start justify-start">
                                                    {
                                                        selectedPartner &&
                                                        <div className=" w-full flex flex-col rounded-lg gap-3" >
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Parceiro:</strong>{selectedPartner?.name ? ` ${selectedPartner.name}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Rua:</strong>{selectedPartner?.addressRoad ? ` ${selectedPartner.addressRoad}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>N:</strong>{selectedPartner?.addressNumber ? ` ${selectedPartner?.addressNumber}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Complemento:</strong>{selectedPartner?.addressComplement ? ` ${selectedPartner?.addressComplement}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Bairro:</strong>{selectedPartner?.addressDistrict ? ` ${selectedPartner.addressDistrict}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Cidade:</strong>{selectedPartner?.addressCity ? ` ${selectedPartner.addressCity}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Estado:</strong>{selectedPartner?.addressState ? ` ${selectedPartner.addressState}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Mesoregião:</strong>{selectedPartner.mesoregion_externalId ? ` ${selectedPartner.mesoregion_externalId}` : ` -`}</a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="lg:w-full flex flex-col gap-2 items-center justify-center h-44 rounded-sm p-2 w-96 mt-6">
                                            <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor mt-2">Arquivos do cliente</p>
                                            <div className="bg-white border dark:bg-secondaryDefaultDark border-gray-200 dark:border-secondaryBorderDark p-2 w-full h-full rounded-lg overflow-auto flex flex-row gap-2 flex-wrap scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-track-gray-300 scrollbar-thumb-zinc-600">
                                                {
                                                    scheduleAttacments?.length > 0 &&
                                                    scheduleAttacments.map(attachment => {
                                                        return (
                                                            <div className="cursor-pointer p-2 h-12 flex w-36 border border-zinc-50 rounded bg-white shadow-lg flex-col items-center justify-center relative">
                                                                <a onClick={() => downloadAttachment(attachment)}>{attachment?.originalName?.slice(0, 10)?.concat(`...`)}</a>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </Container>

    )

}