import React, { useState } from 'react'

export function InputCheckBox({ onClick, value, reference, id, cursor}) {

    const [checked, setChecked] = useState(false)

    function keyBoardNavigation(e) {

        if (e.code === 'Space' || e.code === 'Enter') {
            value = !checked
            setChecked(!checked); 
            onClick(!checked)
        }

    }

    return (

        <div style={{userSelect: 'none'}} value={value} id={id} reference={reference} tabIndex={0} onKeyDown={(e) => keyBoardNavigation(e)} onClick={() => { setChecked(!checked); onClick(!checked) }}
            className={`flex flex-col transition-all duration-100 
            ${cursor ? cursor : 'cursor-pointer'} 
        rounded-full
        focus:border-gray-600
        focus:border-2
        outline-none
        border border-gray-400
        items-center justify-center ${value ? 'bg-primaryDefaultLight' : 'bg-bgPrimaryLight dark:bg-primaryDefaultDark'}  
        text-titleBlackTextLight dark:text-titleGrayTextLight text-lg w-5 h-5`}>
            
        </div>
    )
}