import { responseError } from "../../../utils/responsesFunctions/error.response"
import { mCPF } from "../../../utils/validators/cpfMask"
import { formatSizeCpf } from "../../../utils/validators/formatSizeCpf"
import { formatSizeCnpj } from "../../../utils/validators/formatSizeCnpj"
import { cnpjMask } from "../../../utils/validators/cnpjMask"
import { api } from "../api"
import moment from "moment"

function formattTimeToShow(apiTime) {

    let zPos = apiTime.indexOf('Z')
    let offset = moment().utcOffset()
    let time = apiTime
    time = apiTime.slice(0, zPos)
    time = time.replace('T', ' ')
    let localTime = moment.utc(time).utcOffset(offset).format("L LT");
    return localTime
}

//isAuth token
export async function isAuth(tokenKey) {

    try {
        const isAuthData = await api.get('/auth/isAuth', {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            statusCode: isAuthData.data.status,
            data: isAuthData.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                statusCode: error.response.data.statusCode,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                statusCode: error.response.data.statusCode,
                data: 'Erro inesperado'
            }
        }
    }

}

//get customers
export async function getCustomers(tokenKey) {

    try {
        const customersData = await api.get(`/api/v1/customers`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: customersData.data.data[0]
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//typeAccess get
export async function getTypeAccess(tokenKey) {

    try {
        const typeAccessData = await api.get('/api/v1/typeAccess', {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: typeAccessData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get users
export async function getUsers(tokenKey, status) {
    try {
        const userData = await api.get(`/api/v1/users${status ? `?status=${status}` : ''}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        userData.data.data.map((apiData, index) => {
            if (userData.data.data[index].birthday) {
                userData.data.data[index].birthday = formattTimeToShow(apiData.birthday)
            }
            userData.data.data[index].created_at = formattTimeToShow(apiData.created_at)
        })
        let newData = userData.data.data
        return {
            error: false,
            data: newData
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get users by status
export async function getInternalUsersByStatus(tokenKey, status = null) {

    try {
        const userData = await api.get(`/api/v1/users?typeAccess=1,2,3,4&status=${!status ? '1,0' : status}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        userData.data.data.map((apiData, index) => {
            if (userData.data.data[index].birthday) {
                userData.data.data[index].birthday = formattTimeToShow(apiData.birthday)
            }
            userData.data.data[index].created_at = formattTimeToShow(apiData.created_at)
        })
        let newData = userData.data.data
        return {
            error: false,
            data: newData
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get partners user by status
export async function getPartnersUsersByStatus(tokenKey, status = null) {

    try {
        const userData = await api.get(`/api/v1/users?typeAccess=5,6&status=${!status ? '1,0' : status}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        userData.data.data.map((apiData, index) => {
            if (userData.data.data[index].birthday) {
                userData.data.data[index].birthday = formattTimeToShow(apiData.birthday)
            }
            userData.data.data[index].created_at = formattTimeToShow(apiData.created_at)
        })
        let newData = userData.data.data
        return {
            error: false,
            data: newData
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//user especific
export async function getUserEspecific(tokenKey, userId) {

    try {
        const userData = await api.get(`/api/v1/users/${userId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: userData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get partners
export async function getPartners(tokenKey, status) {

    try {
        const partnersData = await api.get(`/api/v1/partners${status ? `?status=${status}` : ''}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        let newData = partnersData.data.data.map((apiData, index) => {
            if (apiData?.birthday) {
                apiData.birthday = formattTimeToShow(apiData.birthday)
            }
            apiData.created_at = formattTimeToShow(apiData.created_at)
            apiData.isMasterDescription = apiData?.isMaster ? 'Matriz' : 'Filial'

            if (apiData.partnersType == 'PF') {
                apiData.cnpjcpf = mCPF(formatSizeCpf(apiData.cnpjcpf))
            }

            if (apiData.partnersType == 'PJ') {
                apiData.cnpjcpf = cnpjMask(formatSizeCnpj(apiData.cnpjcpf))
            }

            return apiData
        })

        return {
            error: false,
            data: newData
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get myProfile
export async function getMyProfile(tokenKey) {

    try {
        const profileData = await api.get(`/api/v1/myProfile`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: profileData.data.data[0]
        }
    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}


//get clients
export async function getClients(tokenKey) {

    try {
        const clientData = await api.get(`/api/v1/clients`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        clientData.data.data.map((apiData, index) => {
            if (clientData.data.data[index].birthday) {
                clientData.data.data[index].birthday = formattTimeToShow(apiData.birthday)
            }
            clientData.data.data[index].created_at = formattTimeToShow(apiData.created_at)
            clientData.data.data[index].cpf = mCPF(formatSizeCpf(apiData.cpf))
        })
        let newData = clientData.data.data
        return {
            error: false,
            data: newData
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get client especific
export async function getClientEspecific(tokenKey, userId) {

    try {
        const userData = await api.get(`/api/v1/clients/${userId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: userData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get client especific
export async function getPartnersEspecific(tokenKey, partners_id) {

    try {
        const partnerData = await api.get(`/api/v1/partners/${partners_id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: partnerData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getPartnersHoursEspecific
export async function getPartnersHoursEspecific(tokenKey, id) {

    try {
        const partnerHours = await api.get(`/api/v1/partnersHours/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: partnerHours.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//get partnersHours
export async function getPartnersHours(tokenKey, status) {

    try {
        const partnersData = await api.get(`/api/v1/partnersHours${status ? `?status=${status}` : ''}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        let newData = partnersData.data.data.map((apiData, index) => {
            if (apiData?.birthday) {
                apiData.birthday = formattTimeToShow(apiData.birthday)
            }
            apiData.created_at = formattTimeToShow(apiData.created_at)

            if (apiData.partnersType == 'PF') {
                apiData.cnpjcpf = mCPF(formatSizeCpf(apiData.cnpjcpf))
            }

            if (apiData.partnersType == 'PJ') {
                apiData.cnpjcpf = cnpjMask(formatSizeCnpj(apiData.cnpjcpf))
            }

            return apiData
        })

        return {
            error: false,
            data: newData
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}


//getScheduleByDate
export async function getScheduleByDate(tokenKey, date) {

    try {
        const schedule = await api.get(`/api/v1/schedule/${date}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: schedule.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getSchedulePartnerByDate
export async function getSchedulePartnerByDate(tokenKey, partner, date) {

    try {
        const schedulePartner = await api.get(`/api/v1/schedule/${partner},${date}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: schedulePartner.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}


//getScheduleAttachments
export async function getScheduleAttachments(tokenKey, scheduleId) {

    try {
        const scheduleAttchaments = await api.get(`/api/v1/scheduleAttachments/schedule_id/${scheduleId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: scheduleAttchaments.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getPartnersLinkedForUser
export async function getPartnersLinkedForUser(tokenKey, id) {
    try {
        const partnersLinked = await api.get(`/api/v1/linkUsers/user/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: partnersLinked.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getScheduleAttachmentByName
export async function getScheduleAttachmentByName(tokenKey, scheduleId, fileName) {

    try {
        const scheduleAttchaments = await api.get(`/api/v1/scheduleAttachments/schedule_id/${scheduleId}/filename/${fileName}`, {
            responseType: 'blob',
            responseEncoding: 'base64',
            headers: {
                Authorization: `Bearer ${tokenKey}`,
            }
        })

        return {
            error: false,
            data: scheduleAttchaments.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getDashboard
export async function getDashboard(tokenKey) {

    try {
        const dashboard = await api.get('/api/v1/dashboard', {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: dashboard.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getDashboardToOperator
export async function getDashboardToOperator(tokenKey, operator, month) {

    try {
        const dashboard = await api.get(`/api/v1/dashboard/allSchedule/operator/${operator}/month/${month}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: dashboard.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getSignatedContract
export async function getSignatedContract(tokenKey, partner) {

    try {
        const dashboard = await api.get(`/api/v1/dashboard/signatedContract${partner ? `/${partner}` : ''}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: dashboard.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getSignatedContract
export async function getSignatedContractById(tokenKey, id) {

    try {
        const signatedContractData = await api.get(`/api/v1/dashboard/signatedContract/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: signatedContractData.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

// GET - Links Operator To Supervisor
export async function getLinkedOperatorToSupervisor(tokenKey, id) {

    try {
        const linkedSupervisor = await api.get(`/api/v1/linkOperator/operator/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: linkedSupervisor.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

// GET - Links Supervisor To Operator
export async function getLinkedSupervisorToOperator(tokenKey, id) {

    try {
        const linkedSupervisor = await api.get(`/api/v1/linkOperator/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: linkedSupervisor.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

// GET - Links Partners To Consultants
export async function getLinkePartnersWithConsultant(tokenKey, id) {

    try {
        const linkedSupervisor = await api.get(`/api/v1/linkConsultant/consultant/${id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: linkedSupervisor.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

// GET the Partners with linked from supervisor to operator
export async function getPartnersWithLinkedFromSupervisor(tokenKey) {

    try {
        const linkedSupervisor = await api.get(`/api/v1/linkOperator/partners/operator`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: linkedSupervisor.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getClientAttachments
export async function getClientAttachments(tokenKey, clients_id) {

    try {
        const clientAttchaments = await api.get(`/api/v1/clientsAttachments/clients_id/${clients_id}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: clientAttchaments.data.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}

//getClientAttachmentByName
export async function getClientAttachmentByName(tokenKey, clients_id, fileName) {

    try {
        const clientAttchaments = await api.get(`/api/v1/clientsAttachments/clients_id/${clients_id}/filename/${fileName}`, {
            responseType: 'blob',
            responseEncoding: 'base64',
            headers: {
                Authorization: `Bearer ${tokenKey}`,
            }
        })

        return {
            error: false,
            data: clientAttchaments.data
        }

    } catch (error) {
        if (responseError(error).length > 0) {
            return {
                error: true,
                data: (responseError(error))
            }
        } else {
            return {
                error: true,
                data: 'Erro inesperado'
            }
        }
    }
}
