import React, { useEffect, useState } from "react";
//apexcharts
//components
import { Container } from "../../components/container/container";
import { Body } from "../../components/container/Body";
import { TitlePage } from "../../components/titlePages/title.page";
import { Title } from "../../components/titlePages/title";
import { Input } from "../../components/input/input";
import { LabelInput } from "../../components/label/label.input";
import { InputAutoComplete } from "../../components/input/input.autocomplete";
import { Button } from "../../components/buttons/button.default";
import { InputCheckBox } from '../../components/input/input.checkBox'
//api
import { api } from "../../services/api/api";
//context
import { useScreenSizeContext } from "../../contexts/screenSizeContext";
import { useAuth } from "../../contexts/useAuth";
import { validateFields } from "../../utils/form.validator";
import { useThemeContext } from '../../contexts/themeContext';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { getPartners, getUserEspecific } from "../../services/api/callAPIsFunctions/defaultCalls.api";
import { responseError } from "../../utils/responsesFunctions/error.response";
import { DefaultLoader } from "../../components/loaders/defaultLoader";

export function ReportPartners() {
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const { screenX } = useScreenSizeContext()
    const { userData } = useAuth()
    const [dateStart, setDateStart] = useState('')
    const [dateFinal, setDateFinal] = useState('')
    const [status, setStatus] = useState('')
    const [selectedPartner, setSelectedPartner] = useState('')
    const [selectedConfirmation, setSelectedConfirmation] = useState('')
    const [selectedTag, setSelectedTag] = useState('')
    const [selectedViewed, setSelectedViewed] = useState('')
    const statusList = [
        { description: 'Confirmado', id: 0 },
        { description: 'Não compareceu', id: 1 },
        { description: 'Pendente', id: 2 },
        { description: 'Contrato assinado', id: 3 },
        { description: 'Sem direito', id: 4 },
        { description: 'Desmarcou', id: 6 },
        { description: 'Sem interesse', id: 7 },
    ]
    const [userParams, setUserParams] = useState([])
    const [filteredPartners, setFilteredPartners] = useState([])
    const [confirmationList, setConfirmationList] = useState([])
    const [tagsList, setTagsList] = useState([])
    const [selectedReport, setSelectedReport] = useState(userData.typeAccess_id == 5 ? 1 : 2)

    useEffect(() => {
        getData()
    }, [])

    async function getData(){
        setLoading(true)
        try {
            const partnerListData = await getPartners(userData.token)
            const userPartnerAPI = await getUserEspecific(userData.token, userData.id)
            let partnersFilter = partnerListData?.data

            if (userData?.typeAccess_id == 5) {
                partnersFilter = partnerListData?.data.filter(partner => {
                    if (partner?.master_id == userPartnerAPI?.data[0]?.partners_id || partner?.id == userPartnerAPI?.data[0]?.partners_id) {
                        return partner
                    }
                })
            }

            if (userData?.typeAccess_id == 6){
                partnersFilter = partnerListData?.data.filter(partner => {
                    if (partner?.id == userPartnerAPI?.data[0]?.partners_id){
                        return partner
                    }
                })
            }            
            setFilteredPartners(partnersFilter)

            const getConfirmationAPI = await api.get(`/api/v1/confirmation?active=true`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setConfirmationList(getConfirmationAPI?.data?.data.map(item => {
                return {
                    ...item,
                    inputLabel: `${item?.id} - ${item?.description}`
                }
            }))

            const getTagsAPI = await api.get('/api/v1/tags?active=true', {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setTagsList(getTagsAPI?.data?.data?.map(item => {
                return {
                    ...item,
                    inputLabel: `${item?.id} - ${item?.description}`
                }
            }))

            setLoading(false)
        } catch(error){
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleSubmit() {

        const requiredFields = [
            { name: 'initialDate', value: dateStart, required: true, type: 'string' },
            { name: 'endDate', value: dateFinal, required: true, type: 'string' },

        ]
        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        setLoading(true)
        try {            
            let scheduleAttchaments

            if (selectedReport == 1){
                scheduleAttchaments = await api.get(
                    `/api/v1/schedule/export?startDate=${dateStart}&finishDate=${dateFinal}` + userParams.join(''),
                    {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        },
                        responseType: 'blob'
                    }
                )
            }

            if (selectedReport == 2){
                let params = []
                selectedPartner && params.push(`partners_id=${selectedPartner?.id}`)
                selectedConfirmation && params.push(`confirmation_id=${selectedConfirmation?.id}`)
                params = params.join('&')

                scheduleAttchaments = await api.get(
                    `/api/v1/schedulePartners/export?startDate=${dateStart}&finishDate=${dateFinal}${params ? '&' + params : ''}`,
                    {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        },
                        responseType: 'blob'
                    }
                )
            }

            if (selectedReport == 3){

                let params = []
                selectedPartner && params.push(`partners_id=${selectedPartner?.id}`)
                selectedTag && params.push(`tags_id=${selectedTag?.id}`)                
                selectedViewed == 1 || selectedViewed == 0 && params.push(`viewedByMaster=${selectedViewed}`)                    
                params = params.join('&')

                scheduleAttchaments = await api.get(
                    `/api/v1/myClients/exportWithFilter?startDate=${dateStart}&finishDate=${dateFinal}${params ? '&' + params : ''}`,
                    {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        },
                        responseType: 'blob'
                    }
                )
            }            

            const url = window.URL.createObjectURL(scheduleAttchaments.data)
            const downloadElement = document.createElement('a')
            downloadElement.setAttribute('href', url)
            downloadElement.setAttribute('download', `Relatorio_${new Date().toISOString().split('T')[0]}`)
            downloadElement.click()
            setLoading(false)

        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    function handleNewParam(param, setState, data) {
        let newParams = userParams
        newParams = userParams.filter(filter => {
            if (filter.indexOf(param) === -1) {
                return filter
            }
        })

        newParams.push(`&${param}=${data.id}`)
        setUserParams(newParams)

        setState(data)
    }

    function clearStates(){
        setSelectedConfirmation('')
        setSelectedPartner('')
        setSelectedTag('')
        setSelectedViewed('')
        setDateStart('')
        setDateFinal('')
    }

    const options = [
        {
            description: 'Sim',
            value: 1
        },
        {
            description: 'Não',
            value: 0
        },
    ]

    return (
        <Container>
            <TitlePage>
                <Title text={'Relatórios'}>
                </Title>
            </TitlePage>
            <Body>
                {
                    loading ?
                    <DefaultLoader/>
                    :
                    <>
                        <div className="flex flex-row justify-start w-full gap-3">
                            {
                                [5].includes(userData?.typeAccess_id) &&
                                <div className="flex flex-row gap-1 items-center justify-center">
                                    <InputCheckBox
                                        value={selectedReport == 1 ? true : false}
                                        onClick={() => {
                                            clearStates()
                                            setSelectedReport(1)
                                        }}
                                    />
                                    Agenda comercial
                                </div>
                            }
                            <div className="flex flex-row gap-1 items-center justify-center">
                                <InputCheckBox
                                    value={selectedReport == 2 ? true : false}
                                    onClick={() => {
                                        clearStates()
                                        setSelectedReport(2)
                                    }}
                                />
                                Agendamentos de tarefas
                            </div>
                            <div className="flex flex-row gap-1 items-center justify-center">
                                <InputCheckBox
                                    value={selectedReport == 3 ? true : false}
                                    onClick={() => {
                                        clearStates()
                                        setSelectedReport(3)
                                    }}
                                />
                                Clientes
                            </div>
                        </div>
                        <div className="flex lg:flex-row flex-col w-full gap-3">
                            <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start gap-3">
                                <h2 className='flex md:flex justify-center items-center lg:justify-start lg:items-start text-md lg:text-left text-center font-bold text-primaryDefaultLight dark:text-primaryDefaultDarkColor'>Preencha os campos para gerar o relatorio:</h2>
                                <div className="flex sm:flex-row flex-col justify-center items-center lg:justify-start lg:items-start w-full gap-3">
                                    <LabelInput text={'Data de inicio *'} >
                                        <Input id='endDate' type={'date'} onChange={(e) => setDateStart(e.target.value)} value={dateStart} width={screenX > 769 ? '122px' : '224px'} />
                                    </LabelInput>
                                    <LabelInput text={'Data final: *'}>
                                        <Input id='initialDate' type={'date'} onChange={(e) => setDateFinal(e.target.value)} value={dateFinal} width={screenX > 769 ? '122px' : '224px'} />
                                    </LabelInput>
                                </div>
                                {
                                    selectedReport == 1 ?
                                    <>
                                        <div className="flex sm:flex-row flex-col justify-center items-center lg:justify-start lg:items-start w-full gap-3">
                                            <LabelInput text={'Status'}>
                                                <InputAutoComplete type={'text'} data={statusList} onChange={(e) => handleNewParam('status', setStatus, e)} selectedLabel={'description'} optionList={['id', 'description']} width={64} />
                                            </LabelInput>
                                        </div>
                                    </>
                                    : selectedReport == 2 ?
                                    <>                            
                                        <LabelInput text={'Parceiro'}>
                                            <InputAutoComplete 
                                                id={'selectedPartner'}
                                                width={64}
                                                value={selectedPartner ? selectedPartner?.name : ''}
                                                preSelectedValue={selectedPartner ? selectedPartner?.name : ''}
                                                data={filteredPartners}
                                                onChange={(e) => setSelectedPartner(e)}
                                                selectedLabel={`name`} optionList={[`id`, `name`]}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'Confirmação'}>
                                            <InputAutoComplete 
                                                id={'selectedConfirmation'}
                                                value={selectedConfirmation ? selectedConfirmation?.description : ''}
                                                preSelectedValue={selectedConfirmation ? selectedConfirmation?.description : ''}
                                                width={64}
                                                data={confirmationList} 
                                                selectedLabel={`description`} 
                                                optionList={['inputLabel']} 
                                                onChange={(e) => setSelectedConfirmation(e)}
                                            />
                                        </LabelInput>
                                    </>
                                    : selectedReport == 3 &&
                                    <>
                                        <LabelInput text={'Parceiro'}>
                                            <InputAutoComplete 
                                                id={'selectedPartner'}
                                                width={64}
                                                value={selectedPartner ? selectedPartner?.name : ''}
                                                preSelectedValue={selectedPartner ? selectedPartner?.name : ''}
                                                data={filteredPartners}
                                                onChange={(e) => setSelectedPartner(e)}
                                                selectedLabel={`name`} optionList={[`id`, `name`]}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'Tese'}>
                                            <InputAutoComplete
                                                id={'selectedTag'}
                                                value={selectedTag ? selectedTag?.description : ''}
                                                preSelectedValue={selectedTag ? selectedTag?.description : ''}
                                                width={64}
                                                data={tagsList}
                                                selectedLabel={`description`}
                                                optionList={['inputLabel']}
                                                onChange={(e) => {setSelectedTag(e)}}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'Visualizado'}>
                                            <InputAutoComplete
                                                width={64} 
                                                data={options}
                                                selectedLabel={`description`} 
                                                optionList={['description']} 
                                                onChange={(e) => setSelectedViewed(e?.value)}
                                                value={selectedViewed ? options?.find(item => item?.value == selectedViewed)?.description : ''}
                                            />
                                        </LabelInput>
                                    </>
                                }
                                <div className="mt-2 pb-2">
                                    <Button onClick={() => handleSubmit()} approval={true}>Gerar relatório</Button>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Body>
        </Container>

    )
}