// hooks 
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { FiSlash } from 'react-icons/fi'
// components 
import { FaDownload, FaEdit, FaFilter, FaTrash } from 'react-icons/fa'
import { Container } from '../../../components/container/container'
import { Body } from '../../../components/container/Body'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { TableDefault } from '../../../components/table/table.default'
import { Button } from '../../../components/buttons/button.default'
import { Tooltip } from '../../../components/toolTip/toolTip'
import { FaArrowDown } from 'react-icons/fa'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { TableMobile } from '../../../components/table/table.mobile'
import { TablePaginationDefault } from '../../../components/table/tablePagination.default'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
//services
import { getClients } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
//context
import { useThemeContext } from '../../../contexts/themeContext'
import { api } from '../../../services/api/api'
import { useAuth } from '../../../contexts/useAuth'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { mCPF } from '../../../utils/validators/cpfMask'
import { justNumber } from '../../../utils/validators/justNumber'

export function ClientsTable() {

    const { userData } = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [showNotification, setShowNotication] = useState('')
    const [successData, setSuccessData] = useState(false)
    const [clientsList, setClientsList] = useState([])
    const [filterConstructor, setFilterConstructor] = useState({})
    const [filter, setFilter] = useState({})
    const [quantity, setQuantity] = useState(0)
    const { setShowUniversalModal } = useThemeContext()
    const [tagsList, setTagsList] = useState([])
    const [selectedTag, setSelectedTag] = useState('')

    useEffect(() => {    
        async function getTags() {
            setLoading(true)
            try {

                const getTagsAPI = await api.get('/api/v1/tags?active=true', {
                    headers: {
                        Authorization: `Bearer ${userData.token}`
                    }
                })
                setTagsList(getTagsAPI?.data?.data?.map(item => {
                    return {
                        ...item,
                        inputLabel: `${item?.id} - ${item?.description}`
                    }
                }))

                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        if ([5,6].includes(userData?.typeAccess_id)) { getTags() }
    }, [])

    function handleEdit(e) {
        navigate(`/clientsEdit/${e?.id}`)
    }

    async function getExport() {
        const blob = await api.get('/api/v1/clients/export', {
            headers: {
                authorization: `Bearer ${userData.token}`,
            },
            responseType: 'blob',
        })

        const url = URL.createObjectURL(blob.data)
        const linkDownload = document.createElement('a')
        linkDownload.setAttribute('href', url)
        linkDownload.setAttribute('download', `clientes_${new Date().toISOString().split('T')[0]}`)
        linkDownload.click()
    }

    const options = [
        {
            description: 'Sim',
            value: 1
        },
        {
            description: 'Não',
            value: 0
        },
    ]

    return (
        <>
            <ScreenModal title={'Filtros'}>
                {
                    loading
                        ? <DefaultLoader />
                        : (
                            <div className='flex flex-col pt-2 pb-10 px-12 gap-4 items-center'>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Código *</a>
                                    <Input
                                        charLimit={255} id='id' type='string'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, id: justNumber(e.target.value) })}
                                        value={filterConstructor?.id}
                                    ></Input>
                                </label>
                                {
                                    [5,6].includes(userData?.typeAccess_id) &&
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Tese *</a>
                                        <InputAutoComplete
                                            width={80} 
                                            data={tagsList}
                                            selectedLabel={`description`} 
                                            optionList={['inputLabel']} 
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, tags_id: e?.id })}
                                            value={filterConstructor?.tags_id ? tagsList?.find(item => item?.id == filterConstructor?.tags_id)?.description : ''}
                                        />
                                    </label>
                                }
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                    <Input
                                        charLimit={255} id='firstname' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, firstname: e.target.value })}
                                        value={filterConstructor?.firstname}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>CPF *</a>
                                    <Input
                                        charLimit={11} id='cpf' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, cpf: e.target.value })}
                                        value={filterConstructor?.cpf}
                                    ></Input>
                                </label>
                                {
                                    [5].includes(userData?.typeAccess_id) ?
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Visualizado *</a>
                                        <InputAutoComplete
                                                width={80} 
                                                data={options}
                                                selectedLabel={`description`} 
                                                optionList={['description']} 
                                                onChange={(e) => setFilterConstructor({ ...filterConstructor, viewedByMaster: e?.value })}
                                                value={filterConstructor?.viewedByMaster ? options?.find(item => item?.value == filterConstructor?.viewedByMaster)?.description : ''}
                                        />
                                    </label>
                                    :
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                        <Input
                                            charLimit={255} id='email' type='text'
                                            onChange={(e) => setFilterConstructor({ ...filterConstructor, email: e.target.value })}
                                            value={filterConstructor?.email}
                                        />
                                    </label>
                                }
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Cidade *</a>
                                    <Input
                                        charLimit={255} id='addressCity' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, addressCity: e.target.value })}
                                        value={filterConstructor?.addressCity}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>UF *</a>
                                    <Input
                                        charLimit={255} id='addressState' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, addressState: e.target.value })}
                                        value={filterConstructor?.addressState}
                                    ></Input>
                                </label>
                                <div className='mt-5'>
                                    <Button onClick={() => {
                                        setFilter(filterConstructor)
                                        setShowUniversalModal(false)
                                    }}>Filtrar</Button>
                                </div>
                            </div>
                        )
                }
            </ScreenModal>
            <Container>
                <TitlePage displayNotification={showNotification} success={successData} setDisplayNotification={e => setShowNotication(e)}>
                    <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Clientes'}></Title>
                            <div className='flex flex-row gap-2 md:w-48 justify-start items-center'>
                                <h3 className='text-base font-light dark:text-titleGrayTextDark'>{quantity} Clientes</h3>
                                {
                                    [1, 2].includes(userData?.typeAccess_id) ?
                                        <div onClick={() => getExport()} id='generateDownloads' className='hidden md:flex flex-row justify-start items-center cursor-pointer'>
                                            <Tippy key={`tippy-export`} content={<span>Exportar lista em excel</span>}
                                                arrow={true}
                                                animation='perspective'
                                                placement='right'
                                                delay={100}>
                                                <div id='generateDownloads' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                                    <FaDownload className='dark:text-titleGrayTextDark text-titleBlackTextLight text-lg' />
                                                </div>
                                            </Tippy>
                                        </div>
                                        : <></>
                                }
                            </div>
                        </div>
                        <div className='flex flex-col items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/clientsCreate')}>
                            <Button shadow={true} onClick={() => navigate('/clientsCreate')}>+ Novo cliente</Button>
                        </div>
                    </div>
                </TitlePage>
                <Body>
                    <div className='w-full sm:mt-0 mt-2 flex items-center gap-4'>
                        <Button onClick={() => setShowUniversalModal(true)}>
                            <FaFilter className='text-white' /> <p className='text-white pl-2'> Filtrar </p>
                        </Button>
                        {filter && JSON.stringify(filter) != JSON.stringify({})
                            ? <Tippy
                                key={`clearFilter`}
                                content={<span>Limpar filtro</span>}
                                arrow={true}
                                animation='perspective'
                                placement='right'
                                delay={100}>
                                <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                    <FiSlash className='cursor-pointer' onClick={async () => {
                                        setLoading(true)
                                        setFilter()
                                        setFilterConstructor()
                                        await new Promise(resolve => setTimeout(resolve, 500))
                                        setLoading(false)
                                    }} />
                                </div>
                            </Tippy>
                            : <></>
                        }
                    </div>
                    {
                        ![5,6,7].includes(userData?.typeAccess_id) ?
                            <>
                                <TablePaginationDefault
                                    onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                                    quantity={(e) => setQuantity(e)}
                                    apiUrl={'/api/v1/clients/filter'}
                                    filters={filter}
                                    mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                    collumns={[
                                        {
                                            name: 'id',
                                            type: 'number',
                                            description: 'Código'
                                        },
                                        {
                                            name: 'firstname',
                                            type: 'string',
                                            description: 'Nome'
                                        },
                                        {
                                            name: 'lastname',
                                            type: 'string',
                                            func: (item) => {
                                                if (item?.lastname) {
                                                    return item?.lastname
                                                } else {
                                                    return ' - '
                                                }
                                            },
                                            description: 'Sobrenome'
                                        },
                                        {
                                            name: 'cpf',
                                            type: 'string',
                                            func: (item) => {
                                                if (item?.cpf) {
                                                    return mCPF(item?.cpf?.toString())
                                                }
                                            },
                                            description: 'CPF'
                                        },
                                        {
                                            name: 'email',
                                            type: 'string',
                                            func: (item) => {
                                                if (item?.email) {
                                                    return item?.email
                                                } else {
                                                    return ' - '
                                                }
                                            },
                                            description: 'E-mail'
                                        },
                                        {
                                            name: 'created_at',
                                            type: 'date',
                                            description: 'Criado em'
                                        }
                                    ]}
                                />
                            </>
                            :
                            <>
                                <TablePaginationDefault
                                    onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                                    quantity={(e) => setQuantity(e)}
                                    apiUrl={'/api/v1/myClients'}
                                    filters={filter}
                                    mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                                    colSpan={8}
                                    collumns={[
                                        {
                                            name: 'id',
                                            type: 'number',
                                            description: 'Código'
                                        },
                                        {
                                            name: 'firstname',
                                            type: 'string',
                                            description: 'Nome'
                                        },
                                        {
                                            name: 'cpf',
                                            type: 'string',
                                            func: (item) => {
                                                if (item?.cpf) {
                                                    return mCPF(item?.cpf?.toString())
                                                }
                                            },
                                            description: 'CPF'
                                        },
                                        {
                                            name: 'email',
                                            type: 'string',
                                            func: (item) => {
                                                if (item?.email) {
                                                    return item?.email
                                                } else {
                                                    return ' - '
                                                }
                                            },
                                            description: 'E-mail'
                                        },
                                        ...([5,6].includes(userData?.typeAccess_id)
                                            ? [
                                                {
                                                    name: 'tags_description',
                                                    type: 'string',
                                                    description: 'Tese'
                                                }
                                            ] : []
                                        ),
                                        ...(userData?.typeAccess_id == 5
                                            ? [                                                
                                                {
                                                    name: 'viewedByMaster',
                                                    type: 'string',
                                                    func: (item) => {
                                                        if (item?.viewedByMaster || item?.viewedByMaster == null) {
                                                            return (
                                                                <div className='flex flex-row items-center w-full justify-center'>
                                                                    <p 
                                                                        style={{ backgroundColor: '#16a34a' }}
                                                                        className='w-6 h-6 rounded-full'
                                                                    />
                                                                </div>
                                                            );
                                                        } else if (item?.viewedByMaster == false) {
                                                            return (
                                                                <div className='flex flex-row items-center w-full justify-center'>
                                                                    <p 
                                                                        style={{ backgroundColor: '#dc2626' }}
                                                                        className='w-6 h-6 rounded-full'
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                    },
                                                    description: 'Visualizado'
                                                }
                                            ]
                                            : []
                                        ),
                                        {
                                            name: 'created_at',
                                            type: 'date',
                                            description: 'Criado em'
                                        }
                                    ]}
                                />
                            </>
                    }
                </Body>
            </Container>
        </>
    )
}