import React, { useState } from "react";
import { useEffect } from "react";
//apexcharts
//components
import { Container } from "../../components/container/container";
import { Body } from "../../components/container/Body";
import { TitlePage } from "../../components/titlePages/title.page";
import { Title } from "../../components/titlePages/title";
import { Input } from "../../components/input/input";
import { LabelInput } from "../../components/label/label.input";
import { InputAutoComplete } from "../../components/input/input.autocomplete";
import { Button } from "../../components/buttons/button.default";
//api
import { api } from "../../services/api/api";
import { getPartners, getPartnersLinkedForUser, getLinkedSupervisorToOperator, getLinkedOperatorToSupervisor, getPartnersWithLinkedFromSupervisor } from '../../services/api/callAPIsFunctions/defaultCalls.api';
import { getUsers } from "../../services/api/callAPIsFunctions/defaultCalls.api";
//context
import { useScreenSizeContext } from "../../contexts/screenSizeContext";
import { useAuth } from "../../contexts/useAuth";
import { validateFields } from "../../utils/form.validator";
import { useThemeContext } from '../../contexts/themeContext';
import { ScreenModal } from "../../components/modals/notification/screenModal";
import { FiSlash } from "react-icons/fi";
import { FaFilter, FaEdit } from "react-icons/fa";
import { TablePaginationDefault } from "../../components/table/tablePagination.default";
import { justNumber } from "../../utils/validators/justNumber";
import { mCPF } from "../../utils/validators/cpfMask";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { InputCheckBox } from "../../components/input/input.checkBox";
import { responseError } from "../../utils/responsesFunctions/error.response";
import { DefaultLoader } from "../../components/loaders/defaultLoader";

export function Report() {
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const { screenX } = useScreenSizeContext()
    const { userData } = useAuth()
    const [dateStart, setDateStart] = useState('')
    const [dateFinal, setDateFinal] = useState('')
    const [status, setStatus] = useState('')
    const statusList = [
        { description: 'Confirmado', id: 0 },
        { description: 'Não compareceu', id: 1 },
        { description: 'Pendente', id: 2 },
        { description: 'Contrato assinado', id: 3 },
        { description: 'Sem direito', id: 4 },
        { description: 'Desmarcou', id: 6 },
        { description: 'Sem interesse', id: 7 },
    ]
    const [partner, setPartner] = useState([])
    const [partnerList, setPartnerList] = useState([])
    const [usersList, setUsersList] = useState([])
    const [User, setUser] = useState([])
    const [userParams, setUserParams] = useState([])
    const [selectedClient, setSelectedClient] = useState('')
    const [filterConstructor, setFilterConstructor] = useState({})
    const [filter, setFilter] = useState({})
    const [filterClients, setFilterClients] = useState(false)
    const [selectedReport, setSelectedReport] = useState(1)
    const [confirmationList, setConfirmationList] = useState([])
    const [selectedConfirmation, setSelectedConfirmation] = useState('')

    useEffect(() => {
        async function run() {

            let isSupervisorWithLinks = false
            let filteredUsers = []
        
            let partnerData = await getPartners(userData.token)
            
            // REGRA PARA TRAZER APENAS PARCEIROS QUE ESTÃO VINCULADO AO SUPERVISOR
            if ([2].includes(userData.typeAccess_id)) {
                let idsPartnersLink = []

                const partnersLinked = await getPartnersLinkedForUser(userData.token, userData.id)
                partnersLinked.data.filter(partner => { return idsPartnersLink.push(partner?.id) })

                if (partnersLinked.data.length > 0) {
                    isSupervisorWithLinks = true
                    let filteredPartnersList = []

                    partnerData.data.map(partner => {
                        if ((idsPartnersLink.includes(partner?.id)) || (idsPartnersLink.includes(partner?.master_id))) {
                            filteredPartnersList.push(partner?.id)
                        }
                    })

                    partnerData.data = partnerData.data.filter(partner => {
                        if (filteredPartnersList.includes(partner?.id)) {
                            return partner
                        }
                    })
                }
            }

            if ([3].includes(userData.typeAccess_id)) {
                
                const linkedSupervisorAPI = await getLinkedOperatorToSupervisor(userData.token, userData.id)

                if (linkedSupervisorAPI.data.length > 0){
                    const partnersToSupervisor = await getPartnersWithLinkedFromSupervisor(userData.token)
                    partnerData.data = partnersToSupervisor.data
                }

            }

            if (isSupervisorWithLinks) {
                const getOperatorsToSupervisor = await getLinkedSupervisorToOperator(userData.token, userData?.id)
                filteredUsers = getOperatorsToSupervisor?.data
            } else {
                const usersData = await getUsers(userData.token, 1)
                filteredUsers = usersData.data.length > 0 ? usersData.data.filter(user => user.id > 1) : []
            } 
            
            setUsersList(filteredUsers?.map(user => {
                return {
                    ...user,
                    nameInput: `${user?.firstname} ${user?.lastname?.split(' ')[0]}`
                }
            }))
            setPartnerList(partnerData.data)
            
            const getConfirmationAPI = await api.get(`/api/v1/confirmation?active=true`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setConfirmationList(getConfirmationAPI?.data?.data.map(item => {
                return {
                    ...item,
                    inputLabel: `${item?.id} - ${item?.description}`
                }
            }))
        }

        run()
    }, [])

    async function handleSubmit() {

        const requiredFields = [
            { name: 'initialDate', value: dateStart, required: true, type: 'string' },
            { name: 'endDate', value: dateFinal, required: true, type: 'string' },

        ]
        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        setLoading(true)
        try {

            let scheduleAttchaments
            
            if (selectedReport == 1){
                scheduleAttchaments = await api.get(
                    `/api/v1/schedule/export?startDate=${dateStart}&finishDate=${dateFinal}` + userParams.join(''),
                    {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        },
                        responseType: 'blob',
                    }
                )
            }

            if (selectedReport == 2){
                scheduleAttchaments = await api.get(
                    `/api/v1/schedulePartners/export?startDate=${dateStart}&finishDate=${dateFinal}` + userParams.join(''),
                    {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        },
                        responseType: 'blob'
                    }
                )
            }

            const url = window.URL.createObjectURL(scheduleAttchaments.data)
            const downloadElement = document.createElement('a')
            downloadElement.setAttribute('href', url)
            downloadElement.setAttribute('download', `Relatorio_${new Date().toISOString().split('T')[0]}`)
            downloadElement.click()
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function handleNewParam(param, setState, data) {
        let newParams = userParams
        newParams = userParams.filter(filter => {
            if (filter.indexOf(param) === -1) {
                return filter
            }
        })

        newParams.push(`&${param}=${data.id}`)
        setUserParams(newParams)

        setState(data)
    }

    function handleSelectedClient(e) {
        handleNewParam('clients_id', setSelectedClient, e)
        setSelectedClient(e)
        setShowUniversalModal(false)
    }

    function clearStates(){
        setSelectedConfirmation('')
        setPartner('')
        setStatus('')
        setDateStart('')
        setDateFinal('')
    }

    return (
        <>
            <ScreenModal>
                <div className="flex flex-col justify-start w-full h-[40rem] p-6">
                    {
                        filterClients ?
                        <div className='flex flex-col pt-2 pb-10 px-12 gap-4 items-center'>
                            <p className="text-lg text-primaryDefaultLight">Filtro Cliente</p>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Código *</a>
                                <Input
                                    charLimit={255} id='id' type='string'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, id: justNumber(e.target.value) })}
                                    value={filterConstructor?.id}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                <Input
                                    charLimit={255} id='firstname' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, firstname: e.target.value })}
                                    value={filterConstructor?.firstname}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Sobrenome *</a>
                                <Input
                                    charLimit={255} id='lastname' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, lastname: e.target.value })}
                                    value={filterConstructor?.lastname}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>CPF *</a>
                                <Input
                                    charLimit={11} id='cpf' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, cpf: e.target.value })}
                                    value={filterConstructor?.cpf}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                <Input
                                    charLimit={255} id='email' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, email: e.target.value })}
                                    value={filterConstructor?.email}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Cidade *</a>
                                <Input
                                    charLimit={255} id='addressCity' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, addressCity: e.target.value })}
                                    value={filterConstructor?.addressCity}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>UF *</a>
                                <Input
                                    charLimit={255} id='addressState' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, addressState: e.target.value })}
                                    value={filterConstructor?.addressState}
                                ></Input>
                            </label>
                            <div className='mt-5 flex flex-row gap-3 items-center justify-center'>
                                <Button approval={false} onClick={() => setFilterClients(false)}>Voltar</Button>
                                <Button onClick={() => {
                                    setFilter(filterConstructor)
                                    setFilterClients(false)
                                }}>Filtrar</Button>
                            </div>
                        </div>
                        : 
                        <>
                            <div className="flex flex-row gap-2 fixed items-start justify-start top-5 left-3">
                                <Button onClick={() => {setShowUniversalModal(true); setFilterClients(true)}}>
                                    <FaFilter className='text-white' /> <p className='text-white pl-2'> Filtrar </p>
                                </Button>
                                {
                                    filter && JSON.stringify(filter) != JSON.stringify({}) ? 
                                    <Tippy
                                        key={`clearFilter`}
                                        content={<span>Limpar filtro</span>}
                                        arrow={true}
                                        animation='perspective'
                                        placement='right'
                                        delay={100}>
                                        <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center mt-2 gap-2 cursor-pointer'>
                                            <FiSlash className='cursor-pointer' onClick={async () => {
                                                setLoading(true)
                                                setFilter()
                                                setFilterConstructor()
                                                await new Promise(resolve => setTimeout(resolve, 500))
                                                setLoading(false)
                                            }} />
                                        </div>
                                    </Tippy>
                                    : <></>
                                }
                            </div>
                            <div style={{ width: screenX - 80 + 'px', maxWidth: '1150px', maxHeight: screenY - 200 + 'px' }} className="h-full text-center">
                                <TablePaginationDefault
                                    onClick={(e) => e.func ? e.func.execute(e.data) : handleSelectedClient(e)}
                                    apiUrl={'/api/v1/clients/filter'}
                                    quantity={(e) => setQuantity(e)}
                                    filters={filter}
                                    mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleSelectedClient }]}
                                    collumns={[
                                        {
                                            name: 'id',
                                            type: 'number',
                                            description: 'Código'
                                        },
                                        {
                                            name: 'firstname',
                                            type: 'string',
                                            description: 'Nome'
                                        },
                                        {
                                            name: 'cpf',
                                            type: 'string',
                                            func: (item) => {
                                                if (item?.cpf) {
                                                    return mCPF(item?.cpf?.toString())
                                                }
                                            },
                                            description: 'CPF'
                                        },
                                        {
                                            name: 'created_at',
                                            type: 'date',
                                            description: 'Criado em'
                                        }
                                    ]}
                                />
                            </div>
                        </>
                    }
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Relatórios'}>
                    </Title>
                </TitlePage>
                <Body>
                    {
                        loading ? 
                        <DefaultLoader/>
                        :
                        <>
                            <div className="flex flex-row justify-start w-full gap-3">
                                <div className="flex flex-row gap-1 items-center justify-center">
                                    <InputCheckBox
                                        value={selectedReport == 1 ? true : false}
                                        onClick={() => {
                                            setSelectedReport(1)
                                            clearStates()
                                        }}
                                    />
                                    Agenda comercial
                                </div>
                                {
                                    [1].includes(userData.typeAccess_id) &&
                                    <div className="flex flex-row gap-1 items-center justify-center">
                                        <InputCheckBox
                                            value={selectedReport == 2 ? true : false}
                                            onClick={() => {
                                                setSelectedReport(2)
                                                clearStates()
                                            }}
                                        />
                                        Agendamentos de tarefas
                                    </div>
                                }
                            </div>
                            <div className="flex lg:flex-row flex-col w-full gap-3">
                                <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start gap-3">
                                    <h2 className='flex md:flex justify-center items-center lg:justify-start lg:items-start text-md lg:text-left text-center font-bold text-primaryDefaultLight dark:text-primaryDefaultDarkColor'>Preencha os campos para gerar o relatorio:</h2>
                                    <div className="flex sm:flex-row flex-col justify-center items-center lg:justify-start lg:items-start w-full gap-3">
                                        <LabelInput text={'Data de inicio *'} >
                                            <Input id='endDate' type={'date'} onChange={(e) => setDateStart(e.target.value)} value={dateStart} width={'256px'}/>
                                        </LabelInput>
                                        <LabelInput text={'Data final: *'}>
                                            <Input id='initialDate' type={'date'} onChange={(e) => setDateFinal(e.target.value)} value={dateFinal} width={'256px'}/>
                                        </LabelInput>
                                    </div>
                                    {
                                        selectedReport == 1 ?
                                        <>
                                            <div className="flex sm:flex-row flex-col justify-center items-center lg:justify-start lg:items-start w-full gap-3">
                                            <LabelInput text={'Parceiro'}>
                                                <InputAutoComplete 
                                                    type={'text'} 
                                                    data={partnerList} 
                                                    onChange={(e) => handleNewParam('partners_id', setPartner, e)}
                                                    preSelectedValue={partner ? partner?.name : ''}
                                                    selectedLabel={'name'} 
                                                    placeHolder={' - '} 
                                                    optionList={['id','name']} 
                                                    width={64}
                                                />
                                            </LabelInput>
                                                <LabelInput text={'Status'}>
                                                    <InputAutoComplete 
                                                        type={'text'} 
                                                        data={statusList} 
                                                        onChange={(e) => handleNewParam('status', setStatus, e)}
                                                        preSelectedValue={status ? status?.description : ''}
                                                        selectedLabel={'description'} 
                                                        optionList={['id', 'description']} 
                                                        width={64}
                                                    />
                                                </LabelInput>
                                            </div>
                                            <div className="flex sm:flex-row flex-col justify-center items-center lg:justify-start lg:items-start w-full gap-3">                                
                                                <LabelInput text={'Usuário'}>
                                                    <InputAutoComplete 
                                                        type={'text'} 
                                                        disabled={userData?.typeAccess_id === 3 ? true : false} 
                                                        data={usersList} 
                                                        onChange={(e) => handleNewParam('users_created', setUser, e)} 
                                                        preSelectedValue={userData?.typeAccess_id === 3 ? usersList?.filter(e => {return e?.id === userData?.id})[0]?.firstname : ''}
                                                        selectedLabel={'nameInput'} 
                                                        placeHolder={' - '} 
                                                        optionList={['id','nameInput']} 
                                                        width={64} 
                                                    />
                                                </LabelInput>
                                                <LabelInput text={'Cliente'}>
                                                    {
                                                        selectedClient ?
                                                        <p className="flex flex-row justify-start items-center text-sm">
                                                            {selectedClient?.firstname?.length > 38 ? `${selectedClient?.firstname?.slice(0,35)}...` : selectedClient?.firstname}
                                                        </p>
                                                        :
                                                        <></>
                                                    }
                                                    <p 
                                                        className="flex flex-row justify-center items-center text-sm underline cursor-pointer text-primaryDefaultLight"
                                                        onClick={() => {setShowUniversalModal(true)}}
                                                    >
                                                        Selecione um cliente
                                                    </p>
                                                </LabelInput>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="flex sm:flex-row flex-col justify-center items-center lg:justify-start lg:items-start w-full gap-3">                    
                                                <LabelInput text={'Parceiro'}>
                                                    <InputAutoComplete 
                                                        type={'text'} 
                                                        data={partnerList} 
                                                        onChange={(e) => handleNewParam('partners_id', setPartner, e)}
                                                        preSelectedValue={partner ? partner?.name : ''}
                                                        selectedLabel={'name'} 
                                                        placeHolder={' - '} 
                                                        optionList={['id','name']} 
                                                        width={64}
                                                    />
                                                </LabelInput>
                                                <LabelInput text={'Confirmação'}>
                                                    <InputAutoComplete 
                                                        id={'selectedConfirmation'}
                                                        value={selectedConfirmation ? selectedConfirmation?.description : ''}
                                                        preSelectedValue={selectedConfirmation ? selectedConfirmation?.description : ''}
                                                        width={64}
                                                        data={confirmationList} 
                                                        selectedLabel={`description`} 
                                                        optionList={['inputLabel']} 
                                                        onChange={(e) => handleNewParam('confirmation_id', setSelectedConfirmation, e)}
                                                    />
                                                </LabelInput>
                                            </div>
                                        </>
                                    }
                                    <div className="mt-2 pb-2">
                                        <Button onClick={() => handleSubmit()} approval={true}>Gerar relatório</Button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </Body>
            </Container>
        </>

    )
}