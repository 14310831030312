import React, { useEffect, useState } from "react";
//components
import { Container } from "../../components/container/container";
import { InputAutoComplete } from "../../components/input/input.autocomplete";
import { Input } from "../../components/input/input";
import { DefaultLoader } from "../../components/loaders/defaultLoader";
import { Button } from "../../components/buttons/button.default";
import { CalendarMain } from "../../components/calendar/calendar.main";
import { NotificationModal } from "../../components/modals/notification/notification.modal";
//utils
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import { LabelInput } from "../../components/label/label.input";
import { responseError } from "../../utils/responsesFunctions/error.response";
import { getPartnersHours, getUserEspecific, getClientAttachments, getClientAttachmentByName } from "../../services/api/callAPIsFunctions/defaultCalls.api";
//contexts
import { useAuth } from "../../contexts/useAuth";
import { useScreenSizeContext } from "../../contexts/screenSizeContext";
import { useThemeContext } from "../../contexts/themeContext";
//serivces
import { api } from "../../services/api/api";
import { permittedFiles } from "../../utils/filesValidadtor/permittedFiles";
import { FaEdit, FaFilter, FaSave } from "react-icons/fa";
import { TablePaginationDefault } from "../../components/table/tablePagination.default";
import { mCPF } from "../../utils/validators/cpfMask";
import { justNumber } from "../../utils/validators/justNumber";
import { FiSlash } from "react-icons/fi";
import { validateFields } from "../../utils/form.validator";
import { convertURLtoFile } from "../../utils/formatImages/urlToFile";

export function SchedulePartnersCreate() {

    const { userData, setReloadData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const { screenX, screenY } = useScreenSizeContext()
    const [loading, setLoading] = useState(true)
    const [showAnimate, setShowAnimate] = useState(false)
    const [filteredPartners, setFilteredPartners] = useState([])
    const [confirmationList, setConfirmationList] = useState([])
    const [selectedClient, setSelectedClient] = useState([])
    const [selectedPartner, setSelectedPartner] = useState([])
    const [selectedDay, setSelectedDay] = useState(moment())
    const [selectedConfirmation, setSelectedConfirmation] = useState('')
    const [scheduleAttacments, setScheduleAttachments] = useState([])
    const [showModalClientsList, setShowModalClientsList] = useState(false)
    const [filterConstructor, setFilterConstructor] = useState({})
    const [filter, setFilter] = useState({})
    const [quantity, setQuantity] = useState(0)
    const [filterClients, setFilterClients] = useState(false)
    const [labels, setLabels] = useState([])
    const [selectedLabels, setSelectedLabels] = useState([])
    const [selectedLabelDescription, setSelectedLabelDescription] = useState('')

    useEffect(() => {
        getData()
    }, [])
    
    useEffect(() => {
        function cleanState() {
            setSelectedClient('')
            setSelectedPartner('')
            setShowModalClientsList(false)
            setFilterClients(false)
            setSelectedConfirmation('')
        }

        cleanState()
    }, [showUniversalModal])

    async function getData() {
        try {

            setLoading(true)

            await getLabelsAndConfirmation()

            const partnerListData = await getPartnersHours(userData.token)
            const userPartnerAPI = await getUserEspecific(userData.token, userData.id)
            let partnersFilter = partnerListData?.data

            if (userData?.typeAccess_id == 5) {
                partnersFilter = partnerListData?.data.filter(partner => {
                    if (partner?.master_id == userPartnerAPI?.data[0]?.partners_id || partner?.id == userPartnerAPI?.data[0]?.partners_id) {
                        return partner
                    }
                })
            }

            if (userData?.typeAccess_id == 6){
                partnersFilter = partnerListData?.data.filter(partner => {
                    if (partner?.id == userPartnerAPI?.data[0]?.partners_id){
                        return partner
                    }
                })
            }

            setFilteredPartners(partnersFilter)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function getLabelsAndConfirmation(partners_id){
        let params = []
        params.push('active=true')
        params = params.join('&')

        try {
            const getConfirmationAPI = await api.get(`/api/v1/confirmation?active=true`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setConfirmationList(getConfirmationAPI?.data?.data.map(item => {
                return {
                    ...item,
                    inputLabel: `${item?.id} - ${item?.description}`
                }
            }))

            const getLabelsAPI = await api.get('/api/v1/labels?' + params, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            const formatLabels = getLabelsAPI?.data?.data.map(item => {
                return {
                    ...item,
                    inputLabel: `#${item?.description}`
                }
            })
            setLabels(formatLabels)

        } catch(error){
            console.log(error)
        }
    }

    async function handleSubmit() {
        setLoading(true)
        animateLoading(true)

        const requiredFields = [
            { name: 'selectedConfirmation', value: selectedConfirmation, required: true, type: 'object' },
            { name: 'selectedPartner', value: selectedPartner, required: true, type: 'object' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            animateLoading(true)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        let newEvent = {
            scheduleDate: selectedDay.clone().format("YYYY-MM-DD"),
            partners_id: selectedPartner?.id,
            clients_id: selectedClient?.id,
            confirmation_id: selectedConfirmation ? selectedConfirmation?.id : undefined
        }
        try {
            const schedule = await api.post(`/api/v1/schedulePartners`,
                newEvent, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            const scheduleId = schedule.data.data[0].id

            try {
                if (selectedLabels?.length > 0){
                    await api.post('/api/v1/scheduleLabels', {
                        schedulePartners_id: scheduleId,
                        labels: selectedLabels?.map(item => item?.id)
                    }, {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    })
                }
            } catch(error){
                console.log('Erro ao vincular etiquetas', error)
            }

            setShowUniversalModal(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            setReloadData(true)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            animateLoading(true)
            setShowModificationModal(true)
            setShowNotificationModalSuccess(false)
            return setShowNotificationModalText('Erro inesperado')
        }
    }

    async function animateLoading(status) {

        if (status) {
            setShowAnimate(true)
            await new Promise(resolve => setTimeout(resolve, 30))
        } else {
            setShowAnimate(false)
        }
    }

    async function downloadAttachment(file) {
        const attachment = await getClientAttachmentByName(userData.token, selectedClient.id, file.nameFile)
        await resolveAndDownloadBlob(attachment, file.nameFile, file.originalName, file.id)
        const fileToDownload = URL.createObjectURL(attachment.data)

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = fileToDownload;
        a.download = file.nameFile;
        a.click();
        window.URL.revokeObjectURL(fileToDownload);
    }

    async function resolveAndDownloadBlob(response, nameFile, originalName, id) {
        let attach = []
        let extension = originalName?.slice(nameFile.indexOf(`.`), originalName?.length)
        let filename = `${originalName}${extension}`
        filename = decodeURI(filename)

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const file = await convertURLtoFile(url, filename)
        attach.push(
            {
                file,
                id
            }
        )
    }

    async function handleSelectedClient(e) {
        const scheduleAttach = await getClientAttachments(userData.token, e.id)
        setScheduleAttachments(scheduleAttach.data)
        setFilterConstructor({ partners_id: selectedPartner?.id })
        setFilter({ partners_id: selectedPartner?.id })
        setSelectedClient(e)
        setShowModalClientsList(false)
    }

    return (
        <Container>
            <NotificationModal isAuth={true} onlyErrors={true} />
            <div className="flex flex-col bg-bgPrimaryLight dark:bg-primaryDefaultDark shadow-lg overflow-auto py-6 dark:scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-track-zinc-700 scrollbar-thumb-zinc-600">
                {
                    showModalClientsList ?
                    <div className="flex flex-col justify-start w-full h-[32rem] p-6">
                        {
                            filterClients ?
                            <div style={{ width: screenX - 80 + 'px', maxWidth: '1150px', maxHeight: screenY - 200 + 'px' }} className='flex flex-col pt-2 pb-10 px-12 gap-4 items-center'>
                                <p className="text-lg text-primaryDefaultLight">Filtro Cliente</p>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Código *</a>
                                    <Input
                                        charLimit={255} id='id' type='string'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, id: justNumber(e.target.value) })}
                                        value={filterConstructor?.id}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                    <Input
                                        charLimit={255} id='firstname' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, firstname: e.target.value })}
                                        value={filterConstructor?.firstname}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Sobrenome *</a>
                                    <Input
                                        charLimit={255} id='lastname' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, lastname: e.target.value })}
                                        value={filterConstructor?.lastname}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>CPF *</a>
                                    <Input
                                        charLimit={11} id='cpf' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, cpf: e.target.value })}
                                        value={filterConstructor?.cpf}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                    <Input
                                        charLimit={255} id='email' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, email: e.target.value })}
                                        value={filterConstructor?.email}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Cidade *</a>
                                    <Input
                                        charLimit={255} id='addressCity' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, addressCity: e.target.value })}
                                        value={filterConstructor?.addressCity}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>UF *</a>
                                    <Input
                                        charLimit={255} id='addressState' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, addressState: e.target.value })}
                                        value={filterConstructor?.addressState}
                                    ></Input>
                                </label>
                                <div className='mt-5 flex flex-row gap-3 items-center justify-center'>
                                    <Button approval={false} onClick={() => setFilterClients(false)}>Voltar</Button>
                                    <Button onClick={() => {
                                        setFilter(filterConstructor)
                                        setFilterClients(false)
                                    }}>Filtrar</Button>
                                </div>
                            </div>
                            : 
                            <>
                                <div className="flex flex-row gap-2 fixed items-start justify-start top-5 left-3">
                                    <Button onClick={() => {setShowModalClientsList(false)}} approval={false}>Voltar</Button>
                                    <Button onClick={() => {setShowUniversalModal(true); setFilterClients(true)}}>
                                        <FaFilter className='text-white' /> <p className='text-white pl-2'> Filtrar </p>
                                    </Button>
                                    {
                                        filter && JSON.stringify(filter) != JSON.stringify({ partners_id: selectedPartner?.id }) ? 
                                        <Tippy
                                            key={`clearFilter`}
                                            content={<span>Limpar filtro</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='right'
                                            delay={100}>
                                            <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center mt-2 gap-2 cursor-pointer'>
                                                <FiSlash className='cursor-pointer' onClick={async () => {
                                                    setLoading(true)
                                                    setFilter({ partners_id: selectedPartner?.id })
                                                    setFilterConstructor({ partners_id: selectedPartner?.id })
                                                    await new Promise(resolve => setTimeout(resolve, 500))
                                                    setLoading(false)
                                                }} />
                                            </div>
                                        </Tippy>
                                        : <></>
                                    }
                                </div>
                                <div style={{ width: screenX - 80 + 'px', maxWidth: '1150px', maxHeight: screenY - 200 + 'px' }} className="h-full text-center">
                                    <TablePaginationDefault
                                        onClick={(e) => e.func ? e.func.execute(e.data) : handleSelectedClient(e)}
                                        apiUrl={`/api/v1/myClients`}
                                        quantity={(e) => setQuantity(e)}
                                        filters={filter}
                                        mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleSelectedClient }]}
                                        collumns={[
                                            {
                                                name: 'id',
                                                type: 'number',
                                                description: 'Código'
                                            },
                                            {
                                                name: 'firstname',
                                                type: 'string',
                                                description: 'Nome'
                                            },
                                            {
                                                name: 'cpf',
                                                type: 'string',
                                                func: (item) => {
                                                    if (item?.cpf) {
                                                        return mCPF(item?.cpf?.toString())
                                                    }
                                                },
                                                description: 'CPF'
                                            },
                                            {
                                                name: 'created_at',
                                                type: 'date',
                                                description: 'Criado em'
                                            }
                                        ]}
                                    />
                                </div>
                            </>
                        }
                    </div>
                    :
                    <div style={{ width: screenX - 80 + 'px', maxWidth: '1150px', maxHeight: screenY - 200 + 'px' }} className="flex flex-col lg:w-full mx-[1.4rem] justify-start items-center flex-wrap gap-6">
                        {
                            loading ?
                            <div style={{ width: screenX - 140 + 'px', maxWidth: '1150px' }} className="flex flex-col items-center justify-center h-[30rem]">
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <div className="flex flex-col fixed items-start justify-start top-2 left-16 ">
                                    <Button onClick={() => handleSubmit()}><FaSave /> Agendar</Button>
                                </div>
                                <div className="flex flex-row items-start justify-center gap-6">
                                    <div className="flex flex-col sm:w-auto gap-4 items-center sm:items-start sm:justify-center shadow-lg border border-gray-200 dark:border-secondaryBorderDark">
                                        <div className=" flex flex-col p-2 gap-4 rounded-lg items-start justify-start py-4 h-fit max-h-[32rem] overflow-auto scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-track-gray-300 scrollbar-thumb-zinc-600">
                                            <LabelInput text={'Parceiro'} required>
                                                <InputAutoComplete 
                                                    id={'selectedPartner'}
                                                    width={64}
                                                    value={selectedPartner ? selectedPartner?.name : ''}
                                                    data={filteredPartners}
                                                    onChange={(e) => {
                                                        setSelectedPartner(e)
                                                        getLabelsAndConfirmation(e?.id)
                                                        setFilter({ partners_id: e.id })
                                                    }}
                                                    selectedLabel={`name`} optionList={[`id`, `name`]}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'Cliente'}>
                                                {
                                                    selectedClient ?
                                                    <p className="flex flex-row justify-start items-center text-sm">
                                                        {selectedClient?.firstname?.length > 38 ? `${selectedClient?.firstname?.slice(0,35)}...` : selectedClient?.firstname}
                                                    </p>
                                                    :
                                                    <></>
                                                }
                                                {
                                                    selectedPartner?.id ? 
                                                    <p 
                                                        className="flex flex-row justify-center items-center text-sm underline cursor-pointer text-primaryDefaultLight"
                                                        onClick={() => setShowModalClientsList(true)}
                                                    >
                                                        Selecione um cliente
                                                    </p>
                                                    :
                                                    <p 
                                                        className="flex flex-row justify-center items-center text-sm cursor-not-allowed text-primaryDefaultLight"
                                                    >
                                                        Selecione um parceiro
                                                    </p>
                                                }
                                            </LabelInput>
                                            <LabelInput text={'Confirmação *'}>
                                                <InputAutoComplete 
                                                    id={'selectedConfirmation'}
                                                    value={selectedConfirmation ? selectedConfirmation?.description : ''}
                                                    preSelectedValue={selectedConfirmation ? selectedConfirmation?.description : ''}
                                                    width={64}
                                                    data={confirmationList} 
                                                    selectedLabel={`description`} 
                                                    optionList={['inputLabel']} 
                                                    onChange={(e) => {setSelectedConfirmation(e)}}
                                                />
                                            </LabelInput>
                                            <div className=' dark:bg-secondaryDefaultDark bg-bgPrimaryLight lg:w-64 w-64 p-2 flex flex-col gap-4'>
                                                <CalendarMain onClick={(e) => setSelectedDay(e)}/>
                                            </div>
                                            <div className="flex flex-col w-64 gap-2 items-start">
                                                <input
                                                    className="
                                                        h-8 pl-2 border border-gray-400 border-opacity-75 rounded-sm outline-none
                                                        text-sm sm:text-sm transition-all duration-200 focus:shadow-borderShadow
                                                        dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark
                                                        w-64                                                             
                                                    "
                                                    placeholder="Pesquisar etiqueta"
                                                    onChange={(e) => setSelectedLabelDescription(e?.target?.value)}
                                                    value={selectedLabelDescription || ''}
                                                />
                                                <div className="h-20 overflow-auto w-64 flex flex-wrap border border-gray-200 shadow-inner gap-2 p-2">
                                                    {
                                                        labels?.map(label => {
                                                            const find = selectedLabels?.find(item => item?.id == label?.id)
                                                            if (find) { return null }
                                                            return label
                                                        })
                                                        ?.filter(item => item != null)
                                                        ?.filter(item => !!selectedLabelDescription ? item?.description?.toLowerCase()?.includes(selectedLabelDescription?.toLowerCase()) : item)
                                                        ?.map(label => (
                                                            <p onClick={() => setSelectedLabels(prev => ([...prev, label]))} className="h-fit py-1 px-2 text-sm border border-gray-400 cursor-pointer hover:opacity-50 duration-100 transition-all">
                                                                #{String(label?.description)?.substring(0,12)}
                                                            </p>
                                                        ))
                                                    }
                                                </div>
                                                <div className="flex flex-wrap border border-gray-100 w-64 h-full max-h-20 overflow-auto rounded-sm gap-2 p-2">
                                                    {
                                                        selectedLabels?.length > 0 ?
                                                            selectedLabels?.map(label => (
                                                                <p 
                                                                    style={{ backgroundColor: label?.color ?? '#0B2E58' }} 
                                                                    className={`text-sm w-fit h-fit py-1 px-2 rounded-md text-white cursor-pointer hover:opacity-75 duration-100 transition-all`}
                                                                    onClick={() => setSelectedLabels([...selectedLabels?.filter(item => item?.id != label?.id)])}
                                                                >
                                                                    #{String(label?.description).substring(0,13)}
                                                                </p>
                                                            ))
                                                        :
                                                        <p className="text-gray-400 p-2">Etiquetas selecionadas</p>
                                                    }
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className=" p-2 flex flex-col items-center justify-center shadow-inner">
                                        <div id="scheduleCreateData" className="flex lg:flex-row flex-col gap-4 mt-9 lg:w-full lg:items-start lg:justify-between lg:h-72" >
                                            <div className="flex flex-col items-center lg:items-start justify-center lg:w-96 rounded-lg gap-4">
                                                <div className=" border-b border-gray-200 dark:border-secondaryBorderDark w-full">
                                                    <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor">Dados do cliente</p>
                                                </div>
                                                <div className=" flex flex-col items-center justify-center mb-4">
                                                    {
                                                        selectedClient?.id > 0 &&
                                                        <div className="mt-1 flex flex-col rounded-lg gap-3 lg:w-full" >
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`Cliente: ${selectedClient.firstname}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`Cidade: ${selectedClient.addressCity}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`Estado: ${selectedClient.addressState}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`Mesoregião: ${selectedClient.mesoregion_externalId}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`Tese: ${selectedClient.tags_description}`}</a>
                                                            <div className="flex flex-col gap-1">
                                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Observação do cliente:</a>
                                                                <textarea className="text-sm dark:text-titleGrayTextDark p-2 shadow-inner h-16 max-h-16 max-w-24 lg:w-64 dark:bg-secondaryDefaultDark" disabled value={selectedClient.observation} wrap='hard'></textarea>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                            <div className="flex flex-col lg:items-center items-start lg:justify-start lg:w-96 rounded-lg gap-4 pb-11">
                                                <div className="lg:w-full border-b bordar-gray-200 dark:border-secondaryBorderDark lg:h-[1.57rem]  h-10  ">
                                                    <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor">Dados do parceiro</p>
                                                </div>
                                                <div className="mt-1 lg:w-full flex flex-col items-start justify-start">
                                                    {
                                                        selectedPartner?.id && filteredPartners?.length > 0 &&
                                                        <div className=" w-full flex flex-col rounded-lg gap-3" >
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Parceiro:</strong>{selectedPartner?.name ? ` ${selectedPartner.name}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Rua:</strong>{selectedPartner?.addressRoad ? ` ${selectedPartner.addressRoad}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>N:</strong>{selectedPartner?.addressNumber ? ` ${selectedPartner?.addressNumber}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Complemento:</strong>{selectedPartner?.addressComplement ? ` ${selectedPartner?.addressComplement}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Bairro:</strong>{selectedPartner?.addressDistrict ? ` ${selectedPartner.addressDistrict}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Cidade:</strong>{selectedPartner?.addressCity ? ` ${selectedPartner.addressCity}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Estado:</strong>{selectedPartner?.addressState ? ` ${selectedPartner.addressState}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Mesoregião:</strong>{selectedPartner.mesoregion_externalId ? ` ${selectedPartner.mesoregion_externalId}` : ` -`}</a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            selectedClient ?
                                            <div className="lg:w-full flex flex-col gap-2 items-center justify-center h-44 rounded-sm p-2 w-96 mt-6">
                                                <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor mt-2">Arquivos do cliente</p>
                                                <div className="bg-white border dark:bg-secondaryDefaultDark border-gray-200 dark:border-secondaryBorderDark p-2 w-full h-full rounded-lg overflow-auto flex flex-row gap-2 flex-wrap scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-track-gray-300 scrollbar-thumb-zinc-600">
                                                    {
                                                        scheduleAttacments?.length > 0 &&
                                                        scheduleAttacments.map(attachment => {
                                                            return (
                                                                <div className="cursor-pointer p-2 h-12 flex w-36 border border-zinc-50 rounded bg-white shadow-lg flex-col items-center justify-center relative">
                                                                    <a onClick={() => downloadAttachment(attachment)}>{attachment?.originalName?.slice(0, 10)?.concat(`...`)}</a>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            : <></>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                }
            </div>
        </Container>

    )

}