import React, { useEffect, useState } from "react";
//components
import { Container } from "../../components/container/container";
import { InputAutoComplete } from "../../components/input/input.autocomplete";
import { DefaultLoader } from "../../components/loaders/defaultLoader";
import { Button } from "../../components/buttons/button.default";
import { NotificationModal } from "../../components/modals/notification/notification.modal";
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/animations/perspective.css';
//utils
import moment from 'moment';
import 'moment/locale/pt-br';
import { LabelInput } from "../../components/label/label.input";
import { responseError } from "../../utils/responsesFunctions/error.response";
import { getScheduleAttachmentByName, getScheduleAttachments, getUserEspecific, getClientEspecific, getPartnersEspecific } from "../../services/api/callAPIsFunctions/defaultCalls.api";
//contexts
import { useAuth } from "../../contexts/useAuth";
import { useScreenSizeContext } from "../../contexts/screenSizeContext";
import { useThemeContext } from "../../contexts/themeContext";
//serivces
import { api } from "../../services/api/api";
import { maskHours } from "../../utils/mask/maskHours";
import { permittedFiles } from "../../utils/filesValidadtor/permittedFiles";
import { convertURLtoFile } from "../../utils/formatImages/urlToFile";
import { FaSave } from "react-icons/fa";
import { validateFields } from "../../utils/form.validator";

export function PartnerScheduleEdit({ selectedEvent }) {

    const { userData, setReloadData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const { screenX, screenY } = useScreenSizeContext()
    const [loading, setLoading] = useState(true)

    const [selectedClient, setSelectedClient] = useState({})
    const [selectedPartner, setSelectedPartner] = useState([])

    const [selectedDay, setSelectedDay] = useState('')
    const [selectedStartHour, setSelectedStartHour] = useState(0)
    const [selectedFinishHour, setSelectedFinishHour] = useState(0)
    const [selectedObservation, setSelectedObservation] = useState(``)
    const [selectedObservationPartner, setSelectedObservationPartner] = useState(``)
    const [selectedReasonStatus, setSelectedReasonStatus] = useState('')
    const [selectedNewScheduleStatus, setSelectedNewScheduleStatus] = useState('')
    const [selectedStatusSchedule, setSelectedStatusSchedule] = useState(selectedEvent?.status ? selectedEvent?.status : '')
    const [selectedTag, setSelectedTag] = useState(selectedEvent?.tags_description ? selectedEvent?.tags_description : '')
    const [modalButtonSave, setModalButtonSave] = useState(false)

    const [scheduleAttacments, setScheduleAttachments] = useState([])
    const [newScheduleAttacments, setNewScheduleAttachments] = useState([])
    const [attachmentToRemove, setAttachmentToRemove] = useState([])
    const statusList = [
        { description: 'Confirmado', id: 0 },
        { description: 'Não compareceu', id: 1 },
        { description: 'Pendente', id: 2 },
        { description: 'Contrato assinado', id: 3 },
        { description: 'Sem direito', id: 4 },
        { description: 'Desmarcado', id: 6 },
        { description: 'Sem interesse', id: 7 },
    ]
    const [selectedLabels, setSelectedLabels] = useState([])
    const [selectedLabelDescription, setSelectedLabelDescription] = useState('')
    const [labels, setLabels] = useState([])

    useEffect(() => {
        getData()
    }, [])

    async function resolveAndDownloadBlob(response, nameFile, originalName, id) {

        let attach = []
        let extension = originalName.slice(nameFile.indexOf(`.`), originalName?.length)
        let filename = `${originalName}${extension}`;
        filename = decodeURI(filename);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const file = await convertURLtoFile(url, filename)
        attach.push(
            {
                file,
                id
            }
        )
    }

    async function getData() {
        try {
            setLoading(true)

            const clientData = await getClientEspecific(userData.token, selectedEvent?.clients_id)
            const partnerListData = await getPartnersEspecific(userData.token, selectedEvent?.partners_id)
            const scheduleAttach = await getScheduleAttachments(userData.token, selectedEvent.id)
            const getScheduleLabels = await api.get(`/api/v1/scheduleLabels?schedule_id=${selectedEvent.id}`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setSelectedLabels(getScheduleLabels?.data?.data || [])
            await getLabelsAndConfirmation(selectedEvent?.partners_id)

            setScheduleAttachments(scheduleAttach.data)
            setSelectedClient(clientData?.data[0])
            setSelectedDay(moment(selectedEvent.scheduleDate).utc(false))
            setSelectedObservation(selectedEvent.observation)
            setSelectedObservationPartner(selectedEvent.observationPartner)
            setSelectedReasonStatus(selectedEvent.reasonStatus || '')
            setSelectedPartner(partnerListData?.data[0])
            setSelectedStartHour({ hour: selectedEvent.startHour })
            setSelectedFinishHour({ hour: selectedEvent.finishHour })

            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function getLabelsAndConfirmation(partners_id) {
        let params = []
        params.push('active=true')
        partners_id && params.push(`partners_id=${partners_id}`)
        params = params.join('&')

        try {
            const getLabelsAPI = await api.get('/api/v1/labels?' + params, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            const formatLabels = getLabelsAPI?.data?.data.map(item => {
                return {
                    ...item,
                    inputLabel: `#${item?.description}`
                }
            })
            setLabels(formatLabels)

        } catch (error) {
            console.log(error)
        }
    }

    async function downloadAttachment(file) {

        const attachment = await getScheduleAttachmentByName(userData.token, selectedEvent.id, file.nameFile)
        await resolveAndDownloadBlob(attachment, file.nameFile, file.originalName, file.id)
        const fileToDownload = URL.createObjectURL(attachment.data)

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = fileToDownload;
        a.download = file.nameFile;
        a.click();
        window.URL.revokeObjectURL(fileToDownload);
    }

    async function handleSubmit() {

        let requiredFields = []
        if (([2,4,7].includes(selectedStatusSchedule) || [2,4,7].includes(selectedNewScheduleStatus?.id))) {            
            requiredFields.push({ name: 'selectedReasonStatus', value: selectedReasonStatus, required: true, type: 'string' })
        }

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setModalButtonSave(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        setLoading(true)

        try {

            await api.put(`/api/v1/schedule/${selectedEvent.id}/status/${selectedNewScheduleStatus?.id ? selectedNewScheduleStatus?.id : selectedEvent?.status}`, {}, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })

            if (selectedReasonStatus || selectedObservationPartner) {
                await api.put(`/api/v1/schedule/${selectedEvent.id}/observation`, {
                    observationPartner: selectedObservationPartner ? selectedObservationPartner : undefined,
                    reasonStatus: selectedReasonStatus ? selectedReasonStatus : undefined
                }, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`
                    }
                })
            }

            try {
                if (selectedLabels?.length > 0) {
                    await api.post('/api/v1/scheduleLabels', {
                        schedule_id: selectedEvent?.id,
                        labels: selectedLabels?.map(item => item?.id)
                    }, {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    })
                } else {
                    await api.delete(`/api/v1/scheduleLabels/schedule_id/${selectedEvent?.id}`, {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    })
                }
            } catch (error) {
                console.log('Erro ao vincular etiquetas', error)
            }

            if (newScheduleAttacments?.length > 0) {
                await handleAttchamentFile(selectedEvent.id)

            }
            if (attachmentToRemove?.length > 0) {

                await Promise.all(attachmentToRemove.map(async att => {
                    await handleDeleteAttachmentFiles(att)
                }))
            }

            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            setShowUniversalModal(false)
            setReloadData(true)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

    }

    function formatEventStatus() {
        switch (selectedEvent.status) {
            case 0:
                return 'Agendado'
            case 1:
                return 'Não compareceu'
            case 2:
                return 'Pendente'
            case 3:
                return 'Contrato assinado'
            case 4:
                return 'Sem direito'
            case 6:
                return 'Desmarcado'
            case 7:
                return 'Sem interesse'
            default:
                break;
        }
    }

    function addNewAttachment() {
        const inputFile = document.getElementById('fileInput')
        inputFile.click()
    }

    const handleNewPhoto = (photoFile) => {

        const inputFile = document.getElementById('fileInput')

        const checkFile = permittedFiles(photoFile.target.files[0])
        if (checkFile.error) {
            setShowModificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(checkFile.response)
            return removeThisAttachment(photoFile)
        }

        if (newScheduleAttacments.length < 5) {
            setNewScheduleAttachments([photoFile.target.files[0], ...newScheduleAttacments])
        }
    }

    async function removeThisAttachment(attachment, indexAttchament) {
        setNewScheduleAttachments(newScheduleAttacments.filter(attchToRemove => attchToRemove !== attachment))
    }

    async function handleAttchamentFile(scheduleId) {
        try {

            const formData = new FormData();

            newScheduleAttacments.map((attachment) => {
                formData.append('files', attachment);
            })

            const config = {
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                    'content-type': 'multipart/form-data',
                },
            };
            await api.post(`/api/v1/scheduleAttachments/schedule_id/${scheduleId}?notfiy=true`, formData, config)
            return
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

    }

    async function handleDeleteAttachmentFiles(attach) {
        try {

            await api.delete(`/api/v1/scheduleAttachments/${attach.id}`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            return

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {

                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <Container>
            <NotificationModal isAuth={true} dontShow={true}/>
            <div className={`${modalButtonSave ? `opacity-1 flex` : `opacity-0 hidden`} flex-col justify-center items-center w-full h-full absolute z-[9999999993] top-0 left-0`}>
                <div className="absolute z-[9999999992] p-6 bg-white dark:bg-primaryDefaultDarkColor shadow-lg rounded-lg flex flex-col items-center justify-center">
                    {
                        loading ?
                            <DefaultLoader />
                            :
                            <>
                                <a className="mb-6 text-base text-titleBlackTextLight dark:text-titleGrayTextDark">
                                    {
                                        newScheduleAttacments?.length > 0 ?
                                            <>
                                                <span className="text-orange-600">Os arquivos anexados não poderão ser mais removidos.</span><br />
                                            </>
                                            :
                                            <></>
                                    }
                                    <span className="text-sm flex items-center justify-center mt-3">Deseja realmente salvar este agendamento?</span>
                                </a>
                                <div className="flex flex-row gap-2 items-center justify-center">
                                    <Button shadow={true} onClick={() => handleSubmit()}>Sim</Button>
                                    <Button shadow={true} onClick={() => setModalButtonSave(false)} approval={false}>Cancelar</Button>
                                </div>
                            </>
                    }
                </div>
                <div className="w-full h-full absolute z-[999999991] opacity-75 bg-zinc-500"></div>
            </div>
            <div className="flex flex-col bg-bgPrimaryLight  dark:bg-primaryDefaultDark shadow-lg overflow-auto p-6">
                {
                    loading ?
                        <div style={{ width: screenX - 140 + 'px', maxWidth: '1150px' }} className="flex flex-col items-center justify-center h-96">
                            <DefaultLoader />
                        </div>
                        :
                        <div style={{ width: screenX - 140 + 'px', maxWidth: '1150px', maxHeight: screenY - 200 + 'px' }} className=" flex flex-col w-full justify-start items-center gap-6">
                            <div className="flex flex-col fixed items-start justify-start top-2 left-10">
                                <Button onClick={() => setModalButtonSave(true)}><FaSave /> Salvar</Button>
                            </div>
                            <div className="flex flex-row items-start justify-between gap-6 w-full">
                                <div className="flex flex-col gap-4 items-start justify-center shadow-lg border border-gray-200 dark:border-secondaryBorderDark">
                                    <div className=" flex flex-col p-2 gap-4 rounded-lg items-start justify-start py-4 h-72">
                                        <LabelInput text={'Cliente'}>
                                            <InputAutoComplete width={64} disabled={true} preSelectedValue={selectedClient?.firstname} data={[]} onChange={(e) => { setSelectedClient(e) }} selectedLabel={`firstname`} optionList={[`id`, `firstname`]}></InputAutoComplete>
                                        </LabelInput>
                                        <LabelInput text={'Parceiro'}>
                                            <InputAutoComplete width={64} disabled={true} preSelectedValue={selectedPartner.name} data={[]} onChange={(e) => { setSelectedPartner(e) }} selectedLabel={`name`} optionList={[`id`, `name`]}></InputAutoComplete>
                                        </LabelInput>
                                        <LabelInput text={'Estado'}>
                                            <InputAutoComplete width={64} disabled={true} preSelectedValue={selectedPartner.addressState} data={[]} selectedLabel={`addressState`} optionList={[`addressState_externalId`, `addressState`]}></InputAutoComplete>
                                        </LabelInput>
                                        <LabelInput text={'Cidade'}>
                                            <InputAutoComplete width={64} disabled={true} preSelectedValue={selectedPartner.addressCity} data={[]} selectedLabel={`addressCity`} optionList={[`addressCity_externalId`, `addressCity`]}></InputAutoComplete>
                                        </LabelInput>
                                    </div>
                                    <div className="flex flex-col items-start justify-start p-2">
                                        <div id='scheduleCreateCalendar' className="flex flex-col gap-4 rounded-lg items-start justify-start w-64">
                                            <div className="p-2 border-b border-gray-200 dark:border-secondaryBorderDark w-full flex flex-row items-center justify-start gap-2">
                                                <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor mt-1">Data: </p>
                                                <input onChange={(e) => setSelectedDay(moment(e.target.value))} disabled={true} className="mt-1 text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor" value={selectedDay && selectedDay?.clone()?.format('YYYY-MM-DD')} type='date'></input>
                                            </div>
                                            <div className="px-2 flex flex-col items-start justify-start mb-6">
                                                <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor mb-3">Horario</p>
                                                <div className="flex flex-row gap-2 items-start justify-start">
                                                    <LabelInput text={'Inicio'}>
                                                        <InputAutoComplete preSelectedValue={selectedStartHour?.hour && maskHours(selectedStartHour)} disabled={true} value={selectedStartHour.description} width={28} onChange={(e) => setSelectedStartHour(e)} data={[]} optionList={['description']}></InputAutoComplete>
                                                    </LabelInput>
                                                    <LabelInput text={'Fim'}>
                                                        <InputAutoComplete preSelectedValue={selectedFinishHour?.hour && maskHours(selectedFinishHour)} disabled={true} value={selectedFinishHour.description} width={28} onChange={(e) => setSelectedFinishHour(e)} data={[]} optionList={['description']}></InputAutoComplete>
                                                    </LabelInput>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" p-2 flex flex-col items-start justify-center w-[850px]">
                                    <div id="scheduleCreateData" className="flex flex-row gap-4 mt-9 items-start justify-center h-72">
                                        <div className="flex flex-col items-center lg:items-start justify-center lg:w-96 rounded-lg gap-4 ">
                                            <div className="w-full border-b border-gray-200 dark:border-secondaryBorderDark">
                                                <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor">Dados do cliente</p>
                                            </div>
                                            <div className="mt-1 w-full flex flex-col items-start justify-start mb-4">
                                                {
                                                    selectedClient?.id > 0 &&
                                                    <div className=" flex flex-col rounded-lg gap-4 w-full" >
                                                        <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Cliente:</strong>{` ${selectedClient.firstname}`}</a>
                                                        <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Cidade:</strong>{` ${selectedClient.addressCity}`}</a>
                                                        <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Estado:</strong>{` ${selectedClient.addressState}`}</a>
                                                        <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Mesoregião:</strong>{` ${selectedClient.mesoregion_externalId}`}</a>
                                                        <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Atendimento:</strong>{selectedClient?.isRemote ? ' Online' : ' Presencial'}</a>
                                                        <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>CPF:</strong>{` ${selectedClient?.cpf}`}</a>
                                                        {
                                                            selectedClient?.dddmobile && selectedClient?.mobile &&
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Celular:</strong>{` (${selectedClient?.dddmobile}) ${selectedClient?.mobile}`}</a>
                                                        }
                                                        {
                                                            selectedClient?.dddphone && selectedClient?.phone &&
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Telefone:</strong>{` (${selectedClient?.dddphone}) ${selectedClient?.phone}`}</a>
                                                        }
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                        <div className="flex flex-col items-start justify-start w-96 rounded-lg gap-4">
                                            <div className="w-full border-b bordar-gray-200 dark:border-secondaryBorderDark">
                                                <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor">Dados do parceiro</p>
                                            </div>
                                            <div className="mt-1 w-full flex flex-col items-start justify-start">
                                                {
                                                    selectedPartner?.id > 0 ?
                                                        <div className="w-full flex flex-col rounded-lg gap-4" >
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Parceiro:</strong>{` ${selectedPartner?.name}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Cidade:</strong>{` ${selectedPartner?.addressCity}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Estado:</strong>{` ${selectedPartner?.addressState}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Mesoregião:</strong>{` ${selectedPartner?.mesoregion_externalId}`}</a>
                                                        </div>
                                                        :
                                                        <div className="w-full flex flex-col rounded-lg gap-4" >
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Parceiro:</strong>{` ${selectedPartner.name}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Cidade:</strong>{` ${selectedPartner.addressCity}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Estado:</strong>{` ${selectedPartner.addressState}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Mesoregião:</strong>{` ${selectedPartner.mesoregion_externalId}`}</a>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div id='scheduleCreateObservation' className="flex flex-col p-2 gap-4 rounded-lg items-center justify-center w-full">
                                        <div className="flex flex-col gap-2 items-start justify-center w-full">
                                            <div className="border-b border-gray-200 dark:border-secondaryBorderDark w-full mb-4">
                                                <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor">Detalhes</p>
                                            </div>
                                            <div className="flex flex-row w-full">
                                                <textarea 
                                                    value={selectedObservation} 
                                                    wrap="hard" 
                                                    disabled={true} 
                                                    className="w-96 outline-none dark:bg-secondaryDefaultDark focus:shadow-borderShadow transition-all 
                                                    duration-300 h-44 bg-white border border-zinc-100 dark:border-secondaryBorderDark  dark:text-titleGrayTextLight shadow-inner p-2" 
                                                    onChange={(e) => setSelectedObservation(e.target.value)}
                                                />
                                                <div className="w-96 ml-5 flex flex-col overflow-auto dark:scrollbar-thumb-gray-700 dark:scrollbar-track-gray-900 scrollbar-thin scrollbar-track-gray-400 scrollbar-thumb-gray-300">
                                                    <p className="flex w-full border p-2 rounded-sm border-gray-200 shadow-inner h-44 flex-col">
                                                        <span className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor">Tese:</span>
                                                        {selectedTag}
                                                    </p>                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-row gap-2 items-start justify-start h-28 rounded-sm p-2">
                                        <div onClick={() => addNewAttachment()} className="p-2 hover:brightness-125  bg-primaryDefaultLight rounded-lg cursor-pointer h-full flex flex-col items-center justify-center w-40 whi">
                                            <p className=" text-titleGrayTextDark ">Anexos</p>
                                        </div>
                                        <input className={`hidden`} id='fileInput' type={'file'} onChange={(e) => handleNewPhoto(e)}></input>
                                        <div className="bg-white border dark:bg-secondaryDefaultDark border-gray-200 dark:border-secondaryBorderDark p-2 w-full h-full rounded-lg overflow-auto flex flex-row gap-2 flex-wrap">
                                            {
                                                scheduleAttacments?.length > 0 &&
                                                scheduleAttacments.map(attachment => {
                                                    return <div className="cursor-pointer p-2 h-12 flex w-36 border border-zinc-50 rounded bg-white shadow-lg flex-col items-center justify-center relative">
                                                        <a onClick={() => downloadAttachment(attachment)}>{attachment?.originalName.slice(0, 10)?.concat(`...`)}</a>
                                                    </div>
                                                })
                                            }
                                            {
                                                newScheduleAttacments?.length > 0 &&
                                                newScheduleAttacments.map(attachment => {
                                                    return <div className="cursor-pointer p-2 h-12 flex w-36 border border-zinc-50 rounded bg-primaryDefaultLight text-titleGrayTextDark shadow-lg flex-col items-center justify-center relative">
                                                        <span onClick={() => removeThisAttachment(attachment)} className="w-6 h-6 rounded-full hover:brightness-75 bg-zinc-700 absolute left-0 top-0 -translate-x-2 -translate-y-2 text-titleGrayTextDark text-xs flex flex-col items-center justify-center cursor-pointer">x</span>
                                                        <a href={URL.createObjectURL(attachment)} download>{attachment?.name?.slice(0, 8).concat('...').concat(attachment.name.slice(attachment.name.length - 3, attachment.name.length))}</a>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4 items-center justify-start w-full h-96">
                                <div className="p-2 flex flex-col items-start justify-start w-full border-b border-gray-200 dark:border-secondaryBorderDark">
                                    <p className="text-base w-full text-primaryDefaultLight dark:text-primaryDefaultDarkColor mr-5">Área do parceiro</p>
                                </div>
                                <div className="flex flex-row gap-6 justify-start w-full">
                                    <div className="flex flex-col gap-4 p-2 items-start justify-center shadow-lg border border-gray-200 dark:border-secondaryBorderDark">
                                        <div className="flex flex-col gap-2 items-start justify-center w-64">
                                            <div className="flex flex-row gap-2">
                                                {
                                                    //variavel para saber se é parceiro
                                                    1 === 1 ?
                                                    <LabelInput text={'Status'}>
                                                        <InputAutoComplete
                                                            width={64}
                                                            name='selectedNewScheduleStatus'
                                                            preSelectedValue={
                                                                selectedEvent?.status ? statusList.filter(status =>
                                                                    status?.id == selectedEvent?.status
                                                                )[0]?.description : statusList[0]?.description
                                                            }
                                                            data={statusList?.filter(e => ![0,6].includes(e?.id))}
                                                            optionList={['description']}
                                                            selectedLabel={'description'}
                                                            onChange={(e) => { setSelectedNewScheduleStatus(e); setSelectedStatusSchedule('') }}
                                                        />
                                                    </LabelInput>
                                                    :
                                                    <>
                                                        <strong><p>Status:</p></strong>
                                                        <a>{formatEventStatus()}</a>
                                                    </>
                                                }
                                            </div>
                                            <div className="h-64">
                                                {
                                                    [2,4,7].includes(selectedStatusSchedule) || [2,4,7].includes(selectedNewScheduleStatus?.id) ?
                                                        <LabelInput text={'Motivo'}>
                                                            <textarea
                                                                wrap="hard"
                                                                className="text-sm w-64 outline-none focus:shadow-borderShadow transition-all duration-300 h-40 bg-zinc-50 border border-zinc-100 shadow-inner p-2"
                                                                value={selectedReasonStatus ? selectedReasonStatus : ''}
                                                                id='selectedReasonStatus'
                                                                onChange={(e) => setSelectedReasonStatus(e.target.value)}
                                                            />
                                                        </LabelInput>
                                                        : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row gap-4 w-full pb-20">
                                        <div className="flex flex-col w-72 gap-2 items-start">
                                            <input
                                                className="
                                                    h-8 pl-2 border border-gray-400 border-opacity-75 rounded-sm outline-none
                                                    text-sm sm:text-sm transition-all duration-200 focus:shadow-borderShadow
                                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark
                                                    w-72                                                       
                                                "
                                                placeholder="Pesquisar etiqueta"
                                                onChange={(e) => setSelectedLabelDescription(e?.target?.value)}
                                                value={selectedLabelDescription || ''}
                                            />
                                            <div className="max-h-20 h-20 overflow-auto w-72 flex flex-wrap border border-gray-200 shadow-inner gap-2 p-2">
                                                {
                                                    labels?.map(label => {
                                                        const find = selectedLabels?.find(item => item?.id == label?.id)
                                                        if (find) { return null }
                                                        return label
                                                    })
                                                        ?.filter(item => item != null)
                                                        ?.filter(item => !!selectedLabelDescription ? item?.description?.toLowerCase()?.includes(selectedLabelDescription?.toLowerCase()) : item)
                                                        ?.map(label => (
                                                            <p onClick={() => setSelectedLabels(prev => ([...prev, label]))} className="h-fit py-1 px-2 text-sm border border-gray-400 cursor-pointer hover:opacity-50 duration-100 transition-all">
                                                                #{String(label?.description)?.substring(0, 12)}
                                                            </p>
                                                        ))
                                                }
                                            </div>
                                            <div className="flex flex-wrap border border-gray-100 w-72 h-full max-h-32 overflow-auto rounded-sm gap-2 p-2 bg-zinc-50 shadow-inner">
                                                {
                                                    selectedLabels?.length > 0 ?
                                                        selectedLabels?.map(label => (
                                                            <p
                                                                style={{ backgroundColor: label?.color || '#0B2E58' }}
                                                                className={`${!label?.partners_id ? 'cursor-not-allowed' : 'cursor-pointer hover:opacity-75'} text-sm w-fit h-fit py-1 px-2 rounded-md text-white duration-100 transition-all`}
                                                                onClick={() => !label?.partners_id ? {} : setSelectedLabels([...selectedLabels?.filter(item => item?.id != label?.id)])}
                                                            >
                                                                #{String(label?.description).substring(0, 13)}
                                                            </p>
                                                        ))
                                                        :
                                                        <p className="text-gray-400 p-2">Etiquetas selecionadas</p>
                                                }
                                            </div>
                                        </div>
                                        <textarea 
                                            value={selectedObservationPartner ? selectedObservationPartner : ''} 
                                            placeholder='Observação do parceiro' 
                                            maxLength={255}
                                            wrap="hard" 
                                            className="w-full text-sm outline-none focus:shadow-borderShadow transition-all duration-300
                                                    bg-zinc-50 border border-zinc-100 dark:border-secondaryBorderDark 
                                                    dark:bg-secondaryDefaultDark shadow-inner p-2
                                            " 
                                            onChange={(e) => setSelectedObservationPartner(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </Container>
    )

}